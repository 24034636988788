const projection = require('../../../../helpers/projection').default;

const schema = {
	type: (s) => s?.node?.payload?.__typename,
	typeName: (s) => s?.node?.typeName,
	id: (s) => s?.node?.payload?.id,
	race: (s) => s?.node?.payload?.race || 0,
	priceValue: (s) => {
		const value = s?.node?.payload?.price?.all?.USD?.value;
		return Number.isFinite(Number(value)) ? Number(value).toLocaleString('ru', {style: 'decimal'}) : value;
	},
	priceSign: (s) => s?.node?.payload?.price?.all?.USD?.currency?.sign || '',
	location: (s) => s?.node?.payload?.location?.city?.name,
	vinPackageFreeCheck: (s) => s?.node?.payload?.autotest?.vinPackage?.freeCheck,
	photoId: (s) => {
		if (s?.node?.payload?.__typename === 'UsedAuto') {
			return s?.node?.photo?.id || s?.node?.payload?.photos?.main?.id || s?.node?.payload?.photos?.all?.[0]?.id;
		} else if (s?.node?.payload?.__typename === 'NewAuto') {
			return s?.node?.payload?.photos?.main?.id || s?.node?.payload?.photos?.all?.[0]?.id;
		}
	},
	extraSuffix: (s) => s?.node?.photo?.suffix || '',
	photo: (s) => {
		if (s?.node?.payload?.__typename === 'UsedAuto') {
			const id = s?.node?.photo?.id || s?.node?.payload?.photos?.main?.id || s?.node?.payload?.photos?.all?.[0]?.id;
			if (id) {
				return (
					'https://cdn.riastatic.com/photosnew/auto/photo/' +
					s?.node?.payload?.brand?.eng + '_' +
					s?.node?.payload?.model?.eng + '__' + id
				);
			} else {
				return 'https://cdn.riastatic.com/invalid_url';
			}
		} else if (s?.node?.payload?.__typename === 'NewAuto') {
			const url = s?.node?.payload?.photos?.main?.url || s?.node?.payload?.photos?.all?.[0]?.url;
			return url ? `https://cdn.riastatic.com/photosnewr/${url}` : '';
		}
	},
	link: (s) => {
		if (s?.node?.payload?.uri) {
			return s?.node?.payload?.uri;
		} else {
			if (s?.node?.payload?.__typename === 'UsedAuto') {
				return `/auto_${s?.node?.payload?.brand?.eng}_${s?.node?.payload?.model?.eng}_${s?.node?.payload?.id}.html`;
			} else if (s?.node?.payload?.__typename === 'NewAuto') {
				return `/newauto/auto-${s?.node?.payload?.brand?.eng}-${s?.node?.payload?.model?.eng}-${s?.node?.payload?.id}.html`;
			}
		}
	},
	title: (s) =>
		`${s?.node?.payload?.brand?.name || ''} ${s?.node?.payload?.model?.name || ''} ${s?.node?.payload?.year || ''}`,
}

exports.default = projection(schema);
