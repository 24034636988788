export default {
	messages: {
		ru: {
			'Вы скрыли блок потому что': 'Вы скрыли блок потому что',
			'Не интересно': 'Не интересно',
			'Вижу это слишком часто': 'Вижу это слишком часто',
			'Другое': 'Другое',
			'Отмена': 'Отмена',
			'Скрыто': 'Скрыто',
			'Спасибо, в следующий раз мы подберем для вас что-то более актуальное': 'Спасибо, в следующий раз мы подберем для вас что-то более актуальное',
		},
		uk: {
			'Вы скрыли блок потому что': 'Ви приховали блок бо',
			'Не интересно': 'Не цікаво',
			'Вижу это слишком часто': 'Бачу це занадто часто',
			'Другое': 'Інше',
			'Отмена': 'Скасувати',
			'Скрыто': 'Приховано',
			'Спасибо, в следующий раз мы подберем для вас что-то более актуальное': 'Дякуємо, наступного разу ми підберемо для вас щось більш актуальне',
		},
	}
};
