import { mapActions, mapGetters } from 'vuex';
import getPopup from './getPopup';
import links from './brkLink';
import { STORAGE_KEY as SEARCH_HISTORY_KEY } from '../../store/search/history';
import initPush from '../../mixins/pushSubscribeWindow';

import { get, set } from '../../helpers/cookie';

const lelekaClient = require('lelekaClient');

const TARGET = 'first_main_page';
const FAST_SEARCH_COOKIE_NAME = 'test_fast_search';

const searchForm = require('./SearchForm/index.vue').default;
const counters = require('./Counters/index.vue').default;
const rotatorVideostories = require('./RotatorVideoStories/index.vue').default;
const mainInformer = require('./InfoBlock/index.vue').default;
const banner = require('./Banner/index.vue').default;
const searchHistory = require('./SearchHistory/index.vue').default;
const feed = require('./Feed/index.vue').default;
const transportType = require('./TransportType/index.vue').default;
const news = require('./News/index.vue').default;
const recommends = require('./Recommends/index.vue').default;
const community = require('./Community/index.vue').default;
const catalog = require('./Catalog/index.vue').default;
const aboutUs = require('./AboutUs/index.vue').default;
const bannerDesktop = require('../Banners/21727809921~AUTO.RIA_desktop~used_auto_main/index.vue').default;
const promotionalCampaign = require('./PromotionalCampaign/index.vue').default;

const COUNT_NEWS_CLICK_BORDER = 10;

export default {
  name: 'MainPage',
  mixins: [initPush],
  async init({ store, target }) {
    if (target === 'node') {
      return [
        store.dispatch('shared/seo/fetch', { _target: TARGET }),
        store.dispatch('search/state/reset'),
      ];
    }
    return store.dispatch('search/state/reset');
  },
  metaInfo() {
    return {
      title: this.title,
      meta: [
        { name: 'description', content: this.description },
        { name: 'keywords', content: this.keywords },
        { property: 'og:title', content: this.title },
        { property: 'og:description', content: this.description },
        { property: 'og:image', content: 'https://css.riastatic.com/images/og-placeholder.png' },
        { name: 'ROBOTS', content: 'INDEX, FOLLOW' },
      ],
      link: [
        { rel: 'alternate', hreflang: 'ru-UA', href: 'https://auto.ria.com' },
        { rel: 'alternate', hreflang: 'uk-UA', href: 'https://auto.ria.com/uk/' },
        { rel: 'alternate', hreflang: 'ru', href: 'https://auto.ria.com' },
        { rel: 'alternate', hreflang: 'uk', href: 'https://auto.ria.com/uk/' },
        { rel: 'alternate', hreflang: 'x-default', href: 'https://auto.ria.com' },
        { rel: 'canonical', href: `https://auto.ria.com${this.langPrefix ? `${this.langPrefix}/` : ''}` },
      ],
      script: [
        { src: 'https://www.gstatic.com/firebasejs/4.4.0/firebase-app.js' },
        { src: 'https://www.gstatic.com/firebasejs/4.4.0/firebase-messaging.js', async: 'async' },
        { src: `https://p.nexinsight.com.ua/?p=664&cp2=${this.webId || 0}&e=i&t=j`, async: 'async' },
        { src: 'https://webpush.ria.com/js/webpush_fcm.js?v=2.0', async: 'async', defer: 'defer' },
        { innerHTML: 'var APPLENS_PAGE_CATEGORY = "main";' },
      ],
      htmlAttrs: {
        class: this.isMobile ? 'mobile-scroll' : '',
      },
    };
  },
  components: {
    'vue-search-form': searchForm,
    'vue-counters': counters,
    'vue-rotator0': () => import('./Rotator0/index.vue'),
    'vue-rotator': () => import('./Rotator/index.vue'),
    'vue-rotator-video-stories': rotatorVideostories,
    'vue-main-informer': mainInformer,
    'vue-banner': banner,
    'vue-search-history': searchHistory,
    'vue-feed': feed,
    'vue-transport-type': transportType,
    'vue-news': news,
    'vue-recommends': recommends,
    'vue-community': community,
    'vue-catalog': catalog,
    'vue-about-us': aboutUs,
    'vue-banner-desktop': bannerDesktop,
    'vue-promotional-campaign': promotionalCampaign,
  },
  computed: {
    ...mapGetters({
      deviceId: 'shared/device/id',
      slonikInfo: 'news/slonikInfo',
      getSeoData: 'shared/seo/getTargetData',
      lastSearchesServiceStatus: 'search/history/lastSearchesServiceStatus',
      userData: 'Common/userData',
      webId: 'Common/webId',
      testFormCookie: 'others/testFormCookie/get',
      rand1: 'others/rand1',
      version: 'rotator/version',
      mainBRKData: 'infoBlock/main/mainBRKData',
    }),
    newsFirst() {
      const { userPreferences: { reads } = {} } = this.slonikInfo || {};
      return reads >= COUNT_NEWS_CLICK_BORDER;
    },
    seo() {
      return this.getSeoData(TARGET) || {};
    },
    title() {
      return this.seo.title || this.defaultTitle;
    },
    keywords() {
      return this.seo.keywords || this.defaultKeywords;
    },
    description() {
      return this.seo.description || this.defaultDescription;
    },
    isTest() {
      return this.mounted;
    },
    watched() {
      if (this.mounted) {
        try {
          return window.riaHistoryStorage.lelekaList();
        } catch (error) {
          return [];
        }
      }
      return [];
    },
    viewHistory() {
      if (this.mounted) {
        try {
          return window.riaHistoryStorage.lelekaHistoryObjects();
        } catch (error) {
          console.error(error, { id, type });
        }
      }
      return [];
    },
    showPromoCampaign() {
      const query = this.$route.query || {};
      return query.hasOwnProperty('b2b') && query.gclid;
    },
    brkLink() {
      const innerLinks = links
        .filter(({ dates }) => {
          const now = new Date();

          if (dates?.start || dates?.end) {
            const byStart = dates.start ? now >= dates.start : true;
            const byEnd = dates?.end ? dates?.end >= now : true;
            return byStart && byEnd;
          }

          if (dates?.array) {
            const date = this.m(now).format('YYYY.MM.DD');
            return dates?.array?.includes(date);
          }

          return true;
        });
      const link = innerLinks[Math.floor(Math.random() * innerLinks.length)];
      if (link.linkProps.href && link.linkProps.href.indexOf('/') === 0) {
        link.linkProps.href = `${this.langPrefix}${link.linkProps.href}`;
      }
      return link;
    },
  },
  methods: {
    ...mapActions({
      fetchSeoData: 'shared/seo/fetch',
    }),
    closePopup() {
      this.popup = false;
    },
    watchUserDataOnce() {
      const unwatch = this.$watch('userData', function (data) {
        this.$gtm.trackEvent({
          event: 'classicRemarketingUsed',
          user_id: data.userId ? data.userId.toString() : '',
        });
        this.$gtm.trackEvent({
          event: 'crto_homepage',
          crto: {
            email: data.userEmail || '',
          },
        });
        unwatch();
      });
    },
    sendSlonStat($event) {
      const {
        eventId, dataEvent, typeTabForGA = '', fastSearch,
      } = $event;
      if (eventId === 18 && this.sendSlonDefaultTabRedForm) {
        this.gaEvent('MainPageNew', typeTabForGA, 'SearchForm', { nonInteraction: true });
        this._slonik({
          event_id: 18,
          project_id: 1,
          recommendation_version: get('got_leleka_version'),
          form_type: dataEvent,
          ria_options: fastSearch,
          ab_segment: get(FAST_SEARCH_COOKIE_NAME),
        });
        this.sendSlonDefaultTabRedForm = false;
      } else if (eventId === 541 && !this.sendSlonDefaultTabRedForm) {
        this._slonik({
          event_id: 541,
          project_id: 1,
          form_type: dataEvent,
          owner_id: (this.userData && this.userData.userId) ? this.userData.userId : 0,
        });
      } else if (eventId === 922) {
        this._slonik({
          event_id: 922,
          project_id: 1,
          ria_options: fastSearch,
          ab_segment: get(FAST_SEARCH_COOKIE_NAME),
        });
      }
    },
  },
  watch: {
    langId() {
      return this.fetchSeoData({ _target: TARGET });
    },
    mounted() {
      this.watchUserDataOnce();
    },
  },
  data() {
    return {
      popup: false,
      mounted: false,
      CountersOrSearchHistory: '',
      sendSlonDefaultTabRedForm: true,
      showFastSearch: false,
      expiredDate: {
        start: new Date('Fri Feb 24 2023 00:00:01 GMT+0200 (Eastern European Standard Time)'),
        end: new Date('Sun Feb 26 2023 23:59:59 GMT+0200 (Eastern European Standard Time)'),
      },
      defaultTitle: `AUTO.RIA™ — ${this.langId === 2 ? 'Автобазар №1. Купить и продать авто легко как никогда' : 'Автобазар №1. Купити і продати авто легко як ніколи'}`,
      defaultDescription: `AUTO.RIA — ${this.langId === 2 ? 'быстрая продажа и легкая покупка новых и б/у автомобилей. Автобазар, на котором продается 1400 автомобилей каждый день. Поиск по базе объявлений о продаже автомобилей. Каталоги автосалонов и СТО на Авториа.' : 'швидкий продаж та легка купівля нових і вживаних автомобілів. Автобазар, на якому продається 1 400 автомобілів щодня. Пошук по базі оголошень про продаж автомобілів. Каталоги автосалонів і СТО на Авторіа.'}`,
      defaultKeywords: this.langId === 2 ? 'поиск, поиск авто, авто, auto, продажа авто, автомобили, автосалоны, авторынок, базар авто, каталог автомобилей' : 'пошук, пошук авто, авто, auto, продаж авто, автомобілі, автосалони, авторинок, базар авто, каталог автомобілів',
    };
  },
  mounted() {
    this.mounted = true;
    const { query: { nopopups } = {} } = this.$route;
    if (!nopopups) {
      const popup = getPopup.call(this);
      if (popup) {
        this.popup = popup;
      }
    }

    let hasSearchHistory;
    try {
      hasSearchHistory = (JSON.parse(localStorage.getItem(SEARCH_HISTORY_KEY))).length;
    } catch (error) {
    }

    const showLastSearch = Boolean(hasSearchHistory && this.lastSearchesServiceStatus);

    this.CountersOrSearchHistory = showLastSearch
      ? 'vue-search-history'
      : 'vue-counters';

    let fastSearchId = Number(get(FAST_SEARCH_COOKIE_NAME)) || 1;
    if (showLastSearch) {
      fastSearchId = 3;
    }

    set(FAST_SEARCH_COOKIE_NAME, fastSearchId);
    this.showFastSearch = fastSearchId === 1;

    lelekaClient();
    this.initPush();
  },
};
