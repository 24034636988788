import {mapActions, mapMutations, mapGetters} from 'vuex';

const {get} = require('../../../../helpers/cookie');

export default {
	name: 'SearchForm',
	i18n: require('./i18n').default,
	props: ['target', 'withVin', 'indexName', 'searchQuery', 'search_type'],
	components: {
		'vue-categories': require('./Categories/index.vue').default,
		'vue-brands': require('./Brands/index.vue').default,
		'vue-models': require('./Models/index.vue').default,
		'vue-region': require('./Region/index.vue').default,
		'vue-years': require('./Years/index.vue').default,
		'vue-price': require('./Price/index.vue').default,
		'vue-verified-vin': require('./VerifiedVin/index.vue').default
	},
	computed: {
		...mapGetters({
			searchType: 'search/state/type',
			getSearchParamsUsedAutoV1: 'search/state/getSearchParamsUsedAutoV1',
			getSearchParamsUsedAutoV2: 'search/state/getSearchParamsUsedAutoV2',
		})
	},
	methods: {
		...mapActions({
			getCatalogParams: 'search/state/getCatalogParams',
		}),
		...mapMutations({
			setSearchType: 'search/state/setType',
		}),
		async gotoSearch() {
			let urlToRedirect;

			if (!this.indexName && !this.searchQuery) {
				urlToRedirect = (await this.getCatalogParams().catch(console.error) || {}).urlToRedirect;
			}
			if (urlToRedirect) {
				location.href = urlToRedirect.includes('https://')
					? urlToRedirect
					: `${this.langPrefix}${urlToRedirect}`;
			} else {
				let sq = Object
						.keys(this.getSearchParamsUsedAutoV2)
						.filter(param => this.getSearchParamsUsedAutoV2[param])
						.map(param => `${param}=${this.getSearchParamsUsedAutoV2[param]}`)
				;
				if (this.indexName && this.indexName !== 'auto') {
					sq.push(`indexName=${this.indexName}`);
				}
				if (this.searchQuery) {
					sq = sq.concat(this.searchQuery.split('&'));
				}
				sq.push(`size=${get('ipp') || 20}`);
				sq = sq.join('&');
				location.href = `${this.langPrefix}${this.target}${sq ? `?${sq}` : ''}`;
			}
		},
		gotoAdvancedSearch() {
			let cookie_ = Number(get('advanced_search_test'));
			if (cookie_ === 42) {
				let query = {};
				for (let key in this.getSearchParamsUsedAutoV2) {
					if (this.getSearchParamsUsedAutoV2.hasOwnProperty(key) && this.getSearchParamsUsedAutoV2[key]) {
						query[key] = this.getSearchParamsUsedAutoV2[key];
					}
				}
				this.$router.push({ path: `${this.langPrefix}/advanced-search/`, query});
			} else {
				let sq = Object.keys(this.getSearchParamsUsedAutoV1).filter(param => this.getSearchParamsUsedAutoV1[param]).map(param => `${param}=${this.getSearchParamsUsedAutoV1[param]}`);
				if (this.searchQuery) {
					sq = sq.concat(this.searchQuery.split('&'));
				}
				if (this.indexName) {
					sq.push(`indexName=${this.indexName}`);
				}
				location.href = `${this.langPrefix}/advanced-search/${sq ? `?${sq.join('&')}` : ''}`;
			}
		}
	}
};
