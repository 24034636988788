export default {
	messages: {
		ru: {
			'Бу_авто': 'Б/у',
			'Новые_авто': 'Новые',
			'Все': 'Все',
			'Заказ_авто': 'Под пригон'
		},
		uk: {
			'Бу_авто': 'Вживані',
			'Новые_авто': 'Нові',
			'Все': 'Всі',
			'Заказ_авто': 'Під пригон'
		},
	}
};
