import { mapGetters } from 'vuex';
import Main from './Main/index.vue';
import AutoTest from './AutoTest/index.vue';

export default {
	components: {
		'vue-main': Main,
		'vue-autotest': AutoTest
	},
	computed: {
		...mapGetters({
			slonikInfo: 'news/slonikInfo'
		}),
		tagData() {
			let {tagData} = this.slonikInfo || {};
			return tagData;
		}
	},
	i18n: require('./i18n').default
};
