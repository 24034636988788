// Внимание! Повышенная связанность компонент с id 1,2,3 через ProductFactory
const rubrics = {
	1: {id: 1, component: 'vue-products-1', name: 'html.buAuto.byavto',
		sortName:'common.sort',
		sub:{
			1: {id: 1, name: 'html.buAuto.pomarke'},
			2: {id: 2, name: 'html.buAuto.poregiony'},
			3: {id: 3, name: 'html.buAuto.potipy'},
			4: {id: 4, name: 'html.buAuto.pofuel'},
		}},
	2: {id: 2, component: 'vue-products-2', name: 'html.buAuto.novueavto',
		sortName:'common.sort',
		sub:{
			1: {id: 1, name: 'html.buAuto.pomarke'},
			2: {id: 2, name: 'html.buAuto.poregiony'},
			3: {id: 3, name: 'html.buAuto.potipy'},
		}},
	3: {id: 3, component: 'vue-products-3', name: 'common.allMotorists',
		sortName:'mainPage.services.spares',
		sub:{
			1: {id: 1, name: 'html.buAuto.pomarke'},
			2: {id: 2, name: 'html.buAuto.poregiony'},
			3: {id: 3, name: 'html.buAuto.potipy'},
		}},
	4: {id: 4, component: 'vue-news', name: 'html.buAuto.novosti'},
	5: {id: 5, component: 'vue-topics', name: 'common.topic'},
	6: {id: 6, component: 'vue-reviews', name: 'html.buAuto.otzuvu'}
};

let
	gaEventName = "BU",
	gaSubEventName = "Region";
export default {
	i18n: require('./i18n').default,
	components: {
		'vue-products-1': require('./Products-1/index.vue').default,
		'vue-products-2': require('./Products-2/index.vue').default,
		'vue-products-3': require('./Products-3/index.vue').default,
		'vue-news': require('./News/index.vue').default,
		'vue-topics': require('./Topics/index.vue').default,
		'vue-reviews': require('./Reviews/index.vue').default,
	},
	data() {
		return {
			idActive: 1,
			idSubActive: 1,
		}
	},
	computed: {
		list() {
			return Object.values(rubrics)
		},
		currentRubric() {
			const {[this.idActive]: component = {}} = rubrics;
			return component
		},
		subList() {
			return Object.values(rubrics[this.idActive].sub);
		},
		currentRubricName() {
			return gaEventName;
		},
		sortName(){
			return rubrics[this.idActive].sortName;
		}
	},
	watch:{
		idActive(value){
			switch (value){
				case 1:
					gaEventName = 'BU';
					break;
				case 2:
					gaEventName = 'NA';
					break;
				case 3:
					gaEventName = 'AllForAuto';
					break;
				case 4:
					gaEventName = 'News';
					gaSubEventName = "";
					break;
				case 5:
					gaEventName = 'Themes';
					gaSubEventName = "";
					break;
				case 6:
					gaEventName = 'Reviews';
					gaSubEventName = "";
					break;
			}
			this.gaEvent('MainPageNew', 'ClickOn_' + gaEventName, 'go_to_catalog');
		},
		idSubActive(value){
			switch(value){
				case 1:
					gaSubEventName = "-Brand";
					break;
				case 2:
					gaSubEventName = "-Region";
					break;
				case 3:
					gaSubEventName = "-Category";
					break;
				case 4:
					gaSubEventName = "-Drive";
					break;
				default:
					gaSubEventName = "";
			}
			this.gaEvent('MainPageNew', 'ClickOn_' + gaEventName + gaSubEventName, 'go_to_catalog');
		}
	}
};
