import collector from '../../../../helpers/collector';

import {getMounthNameGenitive} from '../../../../helpers/date';

const pattern = {
	'payload.id': (value) => value,
	'payload.createdAt': (value) => value,
	'payload.description': (value) => value,
	'payload.title': (value) => value,
	'payload.uri': (value) => value,
	'payload.comments.count': (value) => value,
	'payload.photos.main.id': (value) => value,
	__img: (value, {payload: {photos: {main: {id} = {}} = {}} = {}}) => id ? `https://cdn.riastatic.com/photosnewr/ria/news_common/__${id}` : '',
	'__createdAtFormated'(value, {payload: {createdAt} = {}} = {}) {
		let now = new Date();
		let date = new Date(this.m(createdAt).toISOString());
		const day = date.getDate();
		const month = date.getMonth();
		const year = date.getFullYear();
		return (year === now.getFullYear() && month === now.getMonth()) ? // сегодня/вчера
			(day === (new Date()).getDate()) ?
				this.$t('Сегодня') : // сегодня
				(day + 1 === (new Date()).getDate()) ?
					this.$t('Вчера') : // вчера
					`${day} ${this.$t(getMounthNameGenitive(month))} ${year}` :
			`${day} ${this.$t(getMounthNameGenitive(month))} ${year}`;
	},
	__addDateDiff2(value, {payload: {createdAt} = {}} = {}) {
		const nowM = this.m();
		const addM = this.m(new Date(this.m(createdAt).toISOString()));
		return (Math.round(nowM.diff(addM, 'days', true)) < 2);
	}
};

export default {
	i18n: require('./i18n').default,
	mixins: [require('../../../../mixins/recommendationFeedLogger')],
	props: ['data', 'index', 'version', 'userData'],
	data() {
		return {
			loadedImg: false,
			failedWebp: false
		};
	},
	computed: {
		item() {
			return collector(this.data || {}, pattern, this);
		},
		description() {
			const {item: {payload: {title, description} = {}} = {}} = this;
			return description ? description : title;
		},
	},
	methods: {
		sendSlonData(eventId) {
			this._slonik({
				event_id: eventId,
				item_type: 3,
				position: this.index,
				news_id: this.id,
				screentype: this.isDesktop ? 1 : 2,
				recommendation_version: this.version
			});
			return true;
		},
		click() {
			this.gaEvent('MainPageNew', `Lenta_Position${this.index}_News`, 'Lenta');
			this.sendSlonData(235);
		},
		imgLoaded() {
			this.loadedImg = true;
		},
		imgFailed() {
			if (this.failedWebp) {
				this.$emit('ImageError', {id: this.item.payload.id, type: this.item.type});
			} else {
				this.failedWebp = true;
			}
		}
	}
};
