import {get} from '../../helpers/cookie';

const popups = [
	{
		component: () => import('../Popups/Meizu/index.vue'),
		isActive: require('../Popups/Meizu/isActive')
	},
	{
		component: () => import('../Popups/PromoApp/index.vue'),
		isActive: require('../Popups/PromoApp/isActive')
	},
	// {
	// 	component: () => import('../Popups/NoddingDog/index.vue'),
	// 	isActive: require('../Popups/NoddingDog/isActive').default
	// }
	// {
	// 	component: () => import('../Popups/InterviewToyota/index.vue'),
	// 	isActive: function () {
	// 		return true;
	// 	}
	// },
];

export default function () {
	let component;
	while (!component && popups.length) {
		let index = Math.floor(Math.random() * popups.length);
		const [{isActive, component} = {}] = popups.splice(index, 1);
		if (typeof isActive === 'function' && isActive.call(this)) {
			return component;
		}
	}
}
