const COOKIE_NAME = 'MeizuOwner_PopupOnMainPage';
import {get} from '../../../helpers/cookie';

module.exports = function isActive() {
	try {
		let data;
		try {
			data = JSON.parse(get(COOKIE_NAME));
		} catch (error) {
			// console.error(error);
		}

		if (!data) {
			const {$route: {query: {utm_campaign, utm_medium, utm_source} = {}} = {}} = this;
			return utm_campaign === 'hot_link' && utm_medium === 'icon_cpc' && utm_source === 'meizu';
		}
	} catch (error) {
		// console.error(error);
	}
};

module.exports.COOKIE_NAME = COOKIE_NAME;
