export default {
	messages: {
		ru: {
            'Порівняйте який автомобіль краще, надійніше і крутіше': 'Сравните какой автомобиль лучше, надежнее и круче',
            'Відмінно': 'Отлично',
            'Порівняти': 'Сравнить',
            'к.с.': 'л.с.',
			'5': 'отлично',
			'4': 'хорошо',
			'3': 'посредственно',
			'2': 'плохо',
			'1': 'ужасно',
			'0': ' ',
		},
		uk: {
			'5': 'відмінно',
			'4': 'добре',
			'3': 'посередньо',
			'2': 'погано',
			'1': 'жахливо',
			'0': ' ',
            'Порівняйте який автомобіль краще, надійніше і крутіше': 'Порівняйте який автомобіль краще, надійніше і крутіше',
            'Відмінно': 'Відмінно',
            'Порівняти': 'Порівняти',
            'к.с.': 'к.с.',
		}
	}
};
