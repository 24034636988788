const monthsNamesGenitive = [
	'catalogs.reviews.monthsNames.january',
	'catalogs.reviews.monthsNames.february',
	'catalogs.reviews.monthsNames.march',
	'catalogs.reviews.monthsNames.april',
	'catalogs.reviews.monthsNames.may',
	'catalogs.reviews.monthsNames.june',
	'catalogs.reviews.monthsNames.july',
	'catalogs.reviews.monthsNames.august',
	'catalogs.reviews.monthsNames.september',
	'catalogs.reviews.monthsNames.october',
	'catalogs.reviews.monthsNames.november',
	'catalogs.reviews.monthsNames.december'
];

export const getMounthNameGenitive = (monthNum) => monthsNamesGenitive[monthNum];
