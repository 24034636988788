export default [
    {
        id: 1,
        field: 'hourly',
        title: 'за годину',
        href: '/last/hour/',
        gaEvents: ['MainPageNew', 'Rotator_LastHour', 'Rotator'],
        defaultValue: 0
    },
    {
        id: 2,
        field: 'dailys',
        title: 'за день',
        href: '/last/today/',
        gaEvents: ['MainPageNew', 'Rotator_LastToday', 'Rotator'],
        defaultValue: 0
    },
    {
        id: 3,
        field: 'vin',
        title: 'перевірено по VIN-коду',
        href: '/proven-car/',
        gaEvents: ['MainPageNew', 'Rotator_vin', 'Rotator'],
        defaultValue: 0
    }
]
