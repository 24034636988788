import {mapGetters} from 'vuex';

export default {
	name: 'LoginBlock',
	props: ['data', 'index', 'version', 'userData'],
	mixins: [require('../../../../mixins/recommendationFeedLogger')],
	data: function () {
		return {
			iframeHeight: 400,
			iframeSrc: '',
			isLogin: false,
		};
	},
	computed: {
		...mapGetters({
			langId: 'lang/id',
		}),
	},
	watch: {
		isLogin(newVal) {
			if (newVal) {
				window.scroll(0, 0);
				window.location.href = `${this.langId === 2 ? '/' : '/uk/'}cabinet/`;
			}
		}
	},
	methods: {
		listenMessage(msg) {
			try {
				let data = JSON.parse(msg.data);
				if (data.height) {
					this.iframeHeight = data.height ? data.height : this.iframeHeight;
				}
				if (data.login === 'ok') {
					window.removeEventListener('message', this.listenMessage, false);
					this.isLogin = true;
				}
			} catch (e) {
			}
		},

		sendSlonData(eventId) {
			this._slonik({
				project_id: 1,
				event_id: eventId,
				recommendation_version: this.version,
				item_type: this.data.blockType + 30,
				position: this.index,
			});
			return true;
		},
	},
	mounted() {
		this.iframeSrc = `/quick-sn/${this.data.blockType === 3 ? 'v2' : 'v1'}/2/${this.langId}?textVersion=${this.data.blockType}&screentype=${this.isDesktop ? 1 : 2}`+
		`&action_block=${this.data.blockType}&current_url=${window.location}&previous_url=${document.referrer}&form_type=1`;
		window.addEventListener('message', this.listenMessage, false);
	}
};
