import {mapActions, mapMutations, mapGetters} from 'vuex';

export default {
	i18n: require('./i18n').default,
	props: {
		search_type: String,
	},
	name: 'SearchForm',
	components: {
		'vue-categories': () => import('./Categories/index.vue'),
		'vue-brands': () => import('./Brands/index.vue'),
		'vue-models': () => import('./Models/index.vue'),
		'vue-region': () => import('./Region/index.vue'),
		'vue-years': () => import('./Years/index.vue'),
		'vue-price': () => import('./Price/index.vue'),
	},
	computed: {
		...mapGetters({
			searchType: 'search/state/type',
			getSearchParamsNewAuto: 'search/state/newAuto/getSearchParamsNewAuto',
			getSearchParamsNewAutoAdvanced: 'search/state/newAuto/getSearchParamsNewAutoAdvanced'
		})
	},
	methods: {
		...mapActions({
			getCatalogParams: 'search/state/getCatalogParams'
		}),
		...mapMutations({
			setSearchType: 'search/state/setType'
		}),
		async gotoSearch() {
			let sq = Object.keys(this.getSearchParamsNewAuto).filter(param => this.getSearchParamsNewAuto[param]).map(param => `${param}=${this.getSearchParamsNewAuto[param]}`).join('&');
			location.href = `${this.langPrefix}/newauto/search/${sq ? `?${sq}` : ''}`;
		},
		gotoAdvancedSearch() {
			let sq = Object.keys(this.getSearchParamsNewAutoAdvanced).filter(param => this.getSearchParamsNewAutoAdvanced[param]).map(param => `${param}=${this.getSearchParamsNewAutoAdvanced[param]}`).join('&');
			location.href = `${this.langPrefix}/newauto/advanced-search/${sq ? `?${sq}` : ''}`;
		}
	}
};
