import {mapGetters, mapActions} from 'vuex';

export default {
	name: 'Categories',
	data: function () {
		return {}
	},
	i18n: require('./i18n').default,
	computed: {
		...mapGetters({
			categoryId: 'search/state/newAuto/getCategory',
			categories: 'shared/categories/newAuto/categoriesAll',
			langId: 'lang/id',
		}),
		options() {
			return Object.values(this.categories).map((item = {name: {}}) => item);
		},
		selected: {
			get: function () {
				return this.categoryId || 0
			},
			set: function (value) {
				this.setCategory(value);
			},
		}
	},
	watch: {
		langId() {
			this.init();
		}
	},
	methods: {
		...mapActions({
			fetchCategories: 'shared/categories/newAuto/fetch',
			setCategory: 'search/state/newAuto/setCategory',
		}),
		init() {
			return this.fetchCategories();
		}
	},
	mounted() {
		return this.init();
	}
};
