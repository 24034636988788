const projection = require('../../../../../helpers/projection').default;

const capitalizeFirstLetter = require('../../../../helpers/capitalizeFirstLetter.js').default;

const schema = {
    id: (s) => s?.id,
    title: (s) => s?.title,
    rating: (s) => s?.rating,
    image: (s) => s?.image?.url,
    link: (s) => s?.link,
    plusesText: (s) => capitalizeFirstLetter(s?.plusesText || ''),
    minusesText: (s) => capitalizeFirstLetter(s?.minusesText || ''),
}

exports.default = projection(schema);
