import collector from '../../../../helpers/collector';
import {statusGraphqlConverter} from '../../../../helpers/mapStatusAdvGraphql';
import _laxios from '../../../../store/_laxios';
const usedAuto = import('./usedAuto.graphql');

const {set} = require('../../../../helpers/cookie');
const EXPIRES_DAY = 86400; // сутки

const pattern = {
	type: (value = '') => value,
	'payload.id': (value) => value,
	'payload.VIN': (value) => value,
	'payload.category.id': (value = 1) => value,
	'payload.brand.name': (value = '') => value,
	'payload.brand.eng': (value) => value || '',
	'payload.brand.id': (value) => value,
	'payload.model.name': (value = '') => value,
	'payload.model.eng': (value) => value || '',
	'payload.model.id': (value) => value,
	'payload.year': (value) => value,
	'payload.status': (value) => statusGraphqlConverter[value],
	'payload.race': (value) => value,
	'payload.uri': (value) => value,
	'payload.createdAt': (value) => value,
	'payload.fuel.name': (value) => value,
	'payload.gearbox.name': (value) => value,
	'payload.autotest.vinPackage': ({freeCheck} = {}) => freeCheck,
	'payload.autotest.plateNumberData.value': (value) => value,
	'payload.location.city.name': (value) => value,
	'payload.location.city.id': (value) => value,
	'payload.price.main.value': (value) => Number.isFinite(Number(value)) ? Number(value).toLocaleString('ru', {style: 'decimal'}) : value,
	'payload.price.main.currency.id': (value) => value,
	'payload.price.main.currency.sign': (value) => value,
	'payload.price.main.currency.abbreviated': (value) => value,
	'payload.price.all.UAH.value': (value) => value,
	'payload.price.all.UAH.currency.id': (value) => value,
	'payload.price.all.UAH.currency.abbreviated': (value) => value,
	'payload.photos.main.id': (value) => value,
	'payload.photos.all.0.id': (value) => value,
	'photo': (value) => (value || {}),
	'photo.suffix': (value) => value || '',
	__photo_id: (value, {photo: {id: idAI} = {}, payload: {photos: {all: {0: {id: idFirst} = {}} = {}, main: {id} = {}} = {}} = {}}) => {
		return idAI || id || idFirst;
	},
	__photo: (value, {__photo_id, payload: {brand: {eng: brandEng} = {}, model: {eng: modelEng} = {}} = {}}) => {
		if (__photo_id) {
			return 'https://cdn.riastatic.com/photosnew/auto/photo/' + brandEng + '_' + modelEng + '__' + __photo_id;
		}
	},
	__extraSuffix: (value, {photo: {suffix = ''} = {}} = {}) => suffix,
	__title: (value, {payload: {brand: {name: brand} = {}, model: {name: model} = {}, year} = {}}) => {
		return `${brand || ''} ${model || ''} ${year || ''}`;
	},
	__link: (value, {payload: {uri} = {}} = {}) => uri,
	__addDateDiff2(value, {payload: {createdAt} = {}} = {}) {
		const nowM = this.m();
		const addM = this.m(createdAt);
		return (Math.round(nowM.diff(addM, 'days', true)) < 2);
	},
	__addDateText(value, {payload: {createdAt} = {}} = {}) {
		const nowM = this.m();
		const addM = this.m(createdAt);

		switch (true) {
			case (nowM.diff(addM, 'hours') <= 12):
				return addM.fromNow();
			case (Math.round(nowM.diff(addM, 'days', true)) < 2):
				return addM.calendar(null, {sameDay: `[${this.$t('Сегодня')}] HH:mm`, lastDay: `[${this.$t('Вчера')}] HH:mm`});
			default: {
				return addM.format('D.MM.YYYY');
			}
		}
	},
	__fixer(value, {payload: {id} = {}, type, __photo}) {
		// Убираем авто без фото
		if (id && !__photo) {
			this.$emit('ImageError', Object.assign(new Error('No photo'), {id, type}));
		}
	}
};

function fetchData({langId = 2, id} = {}) {
	// return Promise.resolve({"data":{"advertisements":[{"id":"29736661","createdAt":1619440570000,"category":{"id":"1"},"brand":{"name":"Volkswagen","eng":"volkswagen","id":"84"},"model":{"name":"Golf V","eng":"golf-v","id":"32460"},"year":2008,"VIN":"WVWZZZ1Kх8Mхххх78","gearbox":{"name":"Ручная / Механика"},"autotest":{"vinPackage":{"freeCheck":false},"plateNumberData":{"value":null,"regionId":5}},"fuel":{"name":"Газ / Бензин"},"location":{"city":{"name":"Луцк","id":"18"}},"photos":{"main":{"id":"390218791"},"all":[{"id":"390218611"},{"id":"390218617"},{"id":"390218618"},{"id":"390218620"},{"id":"390218648"}]},"price":{"main":{"value":5600,"currency":{"id":"1","sign":"$","abbreviated":null}},"all":{"UAH":{"value":155992,"currency":{"id":"3","sign":"₴","abbreviated":"грн"}}}},"uri":"/auto_volkswagen_golf-v_29736661.html","race":174,"status":"ACTIVE"}]}})
	return usedAuto
		.then(({default: query}) => query)
		.then((query) => {
			const variables = {ids: [id], langId};
			return _laxios.graphql
				.request({data: {query, variables}})
				.catch((error) => {
					const message = `[GRAPH_QL API] feed usedAuto: ${JSON.stringify(variables)}`;
					error.message = error?.message ? message + '\n' + error.message : message;
					throw error;
				});
		})
		.then(({data: {advertisements} = {}} = {}) => advertisements);
}

export default {
	i18n: require('./i18n').default,
	mixins: [require('../../../../mixins/recommendationFeedLogger')],
	props: ['data', 'index', 'version', 'userData'],
	data() {
		return {
			failedWebp: false,
			loadedImg: false,
			loadedData: false,
		};
	},
	computed: {
		item() {
			if (!this.loadedData) {
				return collector({}, pattern, this);
			} else {
				return collector(this.data, pattern, this);
			}
		}
	},
	mounted() {
		this.init();
	},
	methods: {
		init() {
			return fetchData({langId: this.langId, id: this.data.payload.id})
				.then((response = {}) => {
					let [data] = response;
					this.data.payload = data;
					this.loadedData = true;
				})
				.catch(console.error);
		},
		sendSlonData(eventId) {
			if (this.item.payload && this.item.payload.id) {
				const {item: {photo: {suffix = ''} = {}} = {}} = this;
				this._slonik({
					project_id: 1,
					event_id: eventId,
					proposal_id: this.item.payload.id,
					recommendation_version: this.version,
					screentype: this.isDesktop ? 1 : 2,
					item_type: 1,
					photo_id: this.item.__photo_id,
					position: this.index,
					photo_suffix: suffix,
					a_b_test_version: suffix.length > 0 ? 1 : 0
				});
				return true;
			} else {
				return false;
			}
		},
		click() {
			this.gaEvent('MainPageNew', `Lenta_Position${this.index}_Bu`, 'Lenta');
			this.sendSlonData(235);
			set('got_leleka_version', this.version, { expires: EXPIRES_DAY });
		},
		loaded() {
			this.loadedImg = true;
		},
		failed(error) {
			if (this.failedWebp) {
				this.$emit('ImageError', Object.assign(error, {id: this.item.payload.id, type: this.item.type}));
			} else {
				this.failedWebp = true;
			}
		}
	}
};
