export default {
	messages: {
		ru: {
			'common.testDrives.definition': 'тест-драйвы',
			'catalogs.custom.others': 'другие',
			'common.show': 'Смотреть',
		},
		uk: {
			'common.testDrives.definition': 'тест-драйви',
			'catalogs.custom.others': 'інші',
			'common.show': 'Дивитись',
		},
	}
};
