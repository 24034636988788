const get = require('../../helpers/Object/get');
const set = require('../../helpers/Object/set');

/**
 * Подготавливает объект для шаблонизатора
 * @param {Object} source
 * @param {{key:String, default:*, handler:Function, args:Array}[]} pattern
 * @param {String} pattern.key - key path
 * @param {*} pattern.default - default value for key
 * @param {Function} pattern.handler - function to handle value in key
 * @param {Array} pattern.args - arguments for handler
 * @param {*} thisArg - context for items `handler`
 * @return {Object}
 */
module.exports = function (source = {}, pattern = {}, thisArg) {
	return Object.entries(pattern).reduce((result, [key, handler]) => {
		const value = get(source, key);
		return set(result, key, handler.apply(thisArg, [value, result]));
	}, {});
};
