import {mapGetters, mapActions} from 'vuex';

export default {
	computed: {
		...mapGetters({
      getVerifiedVIN: 'search/state/getVerifiedVIN'
    }),
		verifiedVIN: {
		  get: function () {
		  	return this.getVerifiedVIN || false;
      },
      set: function (val) {
				this.gaEvent('MainPageNew', 'ClickOn_VIN', 'SearchForm');
				return this.setVerifiedVIN(val);
      }
    }
	},
  methods: {
	  ...mapActions({
			setVerifiedVIN: 'search/state/setVerifiedVIN'
		})
  }
};
