import { mapGetters, mapActions } from 'vuex';
import getFromSearchHistory from '../../../../../helpers/getFromSearchHistory';

import Autocomplete from '../../UsedAuto/Autocomplete/index.vue';
import { suggestBrand } from '../../../../../store/queries/suggest';

export default {
  i18n: require('./i18n').default,
  props: {
    search_type: String,
  },
  data() {
    return {
      mounted: false,
      suggests: [],
      currentSuggest: '',
    };
  },
  components: {
    'vue-autocomplete': Autocomplete,
  },
  watch: {
    category() {
      return this.init();
    },
  },
  computed: {
    ...mapGetters({
      brandsForCategory: 'shared/brands/newAuto/getByCategory',
      brandsPopular: 'shared/brands/newAuto/popular',
      category: 'search/state/newAuto/getCategory',
      getSpecificBrand: 'search/state/newAuto/getSpecificBrand',
      brandsAll: 'shared/brands/newAuto/getAll',
    }),
    selected: {
      get() {
        return this.getSpecificBrand(0) || 0;
      },
      set(value) {
        this.setOnly(value);
      },
    },
    selectedName() {
      const { name = '' } = this.brandsAll[this.selected]
			|| this.suggests.find(({ value }) => Number(this.selected) === Number(value))
			|| {};
      return name;
    },
    optionsRaw() {
      return Object.values(this.brandsForCategory(this.category) || {});
    },
    options() {
      const brandsList = Object.values(this.brandsForCategory(this.category))
        .map(({
          count, main_category: category, marka_id: value, name,
        } = {}) => ({
          category, count, value, name,
        }));
      return brandsList.sort((a, b) => a.name.localeCompare(b.name, 'en'));
    },
    optionsSearched() {
      if (this.mounted) {
        const idsSearched = [...new Set(getFromSearchHistory('brand'))];
        const options = this.optionsRaw;
        const searched = idsSearched.reduce((result, id) => {
          const optionSearched = options.find(({ marka_id } = {}) => marka_id == id);
          if (optionSearched) {
            result.push({ ...optionSearched });
          }
          return result;
        }, []);

        return searched
          .map(({
            count, main_category: category, marka_id: value, name,
          } = {}) => ({
            category, count, value, name,
          }));
      }
      return [];
    },
    optionsPopular() {
      const category = this.category || 0;
      const popularIds = this.brandsPopular(category);
      if (Array.isArray(popularIds)) {
        const popularIdsHead = category === 1 || !category ? popularIds.slice(0, 20) : popularIds.slice(0, 10);

        const popular = popularIdsHead.reduce((result, item) => {
          const { [item]: element } = this.brandsAll;
          if (element) {
            result.push(element);
          }
          return result;
        }, [])
          .map(({
            count, main_category: category, marka_id: value, name,
          } = {}) => ({
            category, count, value, name,
          }));
        return popular.sort((a, b) => a.name.localeCompare(b.name, 'en'));
      }
      return [];
    },
  },
  methods: {
    ...mapActions({
      fetch: 'shared/brands/newAuto/fetchByCategory',
      fetchPopular: 'shared/brands/newAuto/fetchPopularByCategory',
      setOnly: 'search/state/newAuto/setOnlyBrand',
      suggestEntity: 'shared/suggests/entity',
    }),
    init() {
      this.selectedIndex = -1;
      this.suggestedIndex = -1;
      return Promise.all(
        [
          this.fetch({ category: this.category }),
          this.fetchPopular(this.category),
        ].map((item) => item.catch((error) => console.error(error))),
      );
    },
    onSet(payload) {
      this.selected = payload;
    },
    onSuggest(payload) {
      this.currentSuggest = payload;
      this.suggests.splice(0, Infinity);
      return suggestBrand({ text: payload, category: this.category }, this.search_type)
        .then((response) => {
          if (this.currentSuggest === payload) {
            this.suggests.splice(0, Infinity, ...response);
          }
        });
    },
  },
  mounted() {
    this.mounted = true;
    return this.init();
  },
};
