module.exports = {
	statusGraphqlConverter: {
		'ACTIVE': 0,
		'ARCHIVED': 1,
		'EXPIRED': 3,
		'DELETED_BY_ADMIN': 5,
		'AWAIT_FOR_PAYMENT': 8,
		'OVER_FREE_LIMIT': 11,
		'DRAFT': 13,
		'REMOVED_BY_CRON': 14,
		'AUTO_BUY': 21
	}
};
