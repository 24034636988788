export default {
	messages: {
		ru: {
			'Від': 'От',
			'Рік випуску': 'Год выпуска',
			'Оберіть рік': 'Выберите год',
			'Обрати': 'Выбрать',
			'html.buAuto.ot': 'от',
			'html.buAuto.do': 'до',
			'html.buAuto.god': 'Год',
		},
		uk: {
			'Від': 'Від',
			'Рік випуску': 'Рік випуску',
			'Оберіть рік': 'Оберіть рік',
			'Обрати': 'Обрати',
			'html.buAuto.ot': 'від',
			'html.buAuto.do': 'до',
			'html.buAuto.god': 'Рік',
		},
	}
};
