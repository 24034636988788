import {mapGetters} from 'vuex';
import isChildElement from '../../../helpers/isChildElement';
import {set} from '../../../helpers/cookie';
import {COOKIE_NAME} from './isActive';

const COOKIE_INTERVAL = 31536000; // 60 * 60 * 24 * 365 - год

export default {
	name: 'MeizuPopup',
	metaInfo() {
		return {
			bodyAttrs: {
				// 'class': this.open || this.openDeffered ? 'modal-open page-overflow' : ''
				style: this.open || this.openDeffered ? 'position: fixed; top: 0; left: 0; right: 0' : undefined
			}
		};
	},
	data() {
		return {
			open: false,
			openDeffered: false,
			shrink: false
		};
	},
	computed: {
		...mapGetters({
			height: 'shared/device/height'
		}),
		styleObject() {
			return this.shrink ? {bottom: '15px'} : {};
		},
		videoSrc() {
			try {
				const videos = Videos();
				const index = videos.getUnviewedVideoIndex();
				const src = videos.getVideoSrcByIndex(index);
				videos.setAsViewed(index);
				videos.save();
				return src;
			} catch (error) {
				console.error(error);
			}
		}
	},
	methods: {
		close() {
			this.open = false;
			this.openDeffered = false;
			this.$emit('close');
		},
		show() {
			const _this = this;
			this.open = true;
			this.$nextTick(() => {
				setTimeout(() => {
					set(COOKIE_NAME, JSON.stringify(true), {expires: COOKIE_INTERVAL});
					_this.openDeffered = true;
				}, 555);
			});
		},
		checkClose(event) {
			if (!isChildElement(this.$refs.popup, event.target)) {
				this.close();
			}
		},
		setShrink() {
			if (this.open) {
				if (this.height < 800) {
					const element = this.$refs.popup;
					const rect = element.getBoundingClientRect();
					this.shrink = rect.top + window.scrollY + element.offsetHeight + 25 > this.height;
				} else {
					this.shrink = false;
				}
			}
		},
		click() {
			this.gaEvent('MainPageNew', 'Meizu_perehod', 'Meizu_pop-up');
		}
	},
	watch: {
		height() {
			this.setShrink();
		}
	},
	mounted() {
		this.show();
		this.gaEvent('MainPageNew', 'Meizu_Pokaz', 'Meizu_pop-up');
	},
	updated() {
		this.setShrink();
	}
};
