const COOKIE_NAME = 'show_pop_up_feed';
import {get} from '../../../helpers/cookie';
import getDeviceOs from '../../../helpers/getDeviceOs';

module.exports = function isActive() {
	let data;
	let device = getDeviceOs();
	try {
		data = JSON.parse(get(COOKIE_NAME));
	} catch (error) {
		console.error(error.message);
	}

	if (!data && device === 'android' || device === 'ios') {
		const {$route: {query: {utm_medium, utm_source} = {}} = {}} = this;
		return utm_medium === 'clikukrnet' && utm_source === 'ukrnet';
	}
};

module.exports = COOKIE_NAME;
