import {mapGetters} from 'vuex';

const {set, get} = require('../../../../helpers/cookie');
export default {
	data() {
		return {
			loadedImg: false,
			webp: false,
			ABTest: 0,
			cookieName: 'ab-link-video-stories',
			expireDate: 60 * 60 * 24 * 30,
		};
	},
	props: ['dataItem'],
	methods: {
		setABTestCookie() {
			const cookie = get(this.cookieName);
			if (cookie) {
				this.ABTest = Number(cookie);
			} else {
				set(this.cookieName, this.rand1 + 1, {expires: this.expireDate});
				this.ABTest = this.rand1 + 1;
			}
		},
		ga() {
			if (this.ABTest === 1) {
				this.gaEvent('MainPage', 'ClickOn_link_listing', 'informer_videomassege_target_blank');
			} else {
				this.gaEvent('MainPage', 'ClickOn_link_listing', 'informer_videomassege');
			}
		}
	},
	computed: {
		photoSRC() {
			if (this.brandEng && this.modelEng && this.photoId) {
				this.webp = true;
				return 'https://cdn.riastatic.com/photosnew/auto/photo/' + this.brandEng + '_' + this.modelEng + '__' + this.photoId + 'b';
			} else {
				this.webp = false;
				return 'https://cdn.riastatic.com/docs/pictures/common/1/1/12385/12385';
			}
		},
		brandEng() {
			return this._result(this.dataItem, 'brand.eng');
		},
		modelEng() {
			return this._result(this.dataItem, 'model.eng');
		},
		photoId() {
			return this._result(this.dataItem, 'photos.main.id');
		},
		priceValue() {
			return this._result(this.dataItem, 'price.main.value');
		},
		priceSign() {
			return this._result(this.dataItem, 'price.main.currency.sign') === '₴' ? this._result(this.dataItem, 'price.main.currency.abbreviated') : this._result(this.dataItem, 'price.main.currency.sign');
		},
		...mapGetters({
			rand1: 'others/rand1',
		}),
	},
	mounted() {
		this.setABTestCookie();
	},
	i18n: {
		messages: {
			uk: {
				'тис. км': 'тис. км',
			},
			ru: {
				'тис. км': 'тыс. км',
			}
		}
	},
	watch: {
		dataItem(newValue) {
			this.loadedImg = true;
		}
	}
};
