export default {
	i18n: {
		messages: {
			ru: {
				'Спасибо за подписку': 'Спасибо за подписку',
				'Теперь вы подписаны на оповещения!': 'Теперь вы подписаны на оповещения!',
			},
			uk: {
				'Спасибо за подписку': 'Дякую за підписку',
				'Теперь вы подписаны на оповещения!': 'Тепер ви підписані на сповіщення!',
			}
		}
	},
	methods: {
		initPush() {
			const labelProjectId = 2;
			const welcomeNotification = {
				'title': this.$t('Спасибо за подписку'),
				'body': this.$t('Теперь вы подписаны на оповещения!'),
				'icon': 'https://auto.ria.com/autoria192.png',
			};
			// показується стандартне вікно підписки, прибрати, щоб показувало "задизайнене"
			const message = false;

			if (window && window.PushMessanger) {
				window.push_subscribe_object = new PushMessanger(labelProjectId, welcomeNotification, message).load();
			}
		}
	}
};
