import {mapActions} from 'vuex';

export default {
	mixins: [require('../../../../mixins/recommendationFeedLogger')],
	i18n: require('./i18n').default,
	props: {
		data: Object,
		index: Number,
		version: String,
		userData: Object
	},
	data() {
		return {
			isLoadedData: false,
			details: {},
		};
	},
	computed: {
		bodyName() {
			return this.details?.body?.name || '';
		},
		yearFrom() {
			return (Number(this.details?.yearFrom) > 1901) ? this.details.yearFrom : '';
		},
		yearTo() {
			return Number(this.details?.yearTo) || new Date().getFullYear();
		},
		yearsView() {
			switch (true) {
				case this.yearFrom === this.yearTo:
					return this.yearTo;
				case !this.yearFrom && this.yearTo === new Date().getFullYear():
					return '';
				case !this.yearFrom:
					return `${this.$t('до')} ${this.yearTo} ${this.$t('року')}`;
				default:
					return `${this.yearFrom}-${this.yearTo}`;
			}
		},
		description() {
			if (this.details?.brand && this.details?.model) {
				return `${this.bodyName} ${this.details?.brand.name} ${this.details?.model.name} ${this.yearsView}`;
			} else {
				return '';
			}
		},
		priceUsd() {
			return this.details?.price?.prices?.usd || 0;
		},
		priceEur() {
			return this.details?.price?.prices?.eur || 0;
		},
		priceUah() {
			return this.details?.price?.prices?.uah || 0;
		},
		bidCount() {
			return this.details?.price?.bidCount || 0;
		},
		urlCalculator() {
			const { mark, model, yearFrom, yearTo } = this.data.payload;
			const params = [
				`marka_id=${mark}`,
				`model_id=${model}`,
				(yearFrom ? `s_years=${yearFrom}` : ''),
				(yearTo && yearTo !== 2021 ? `po_years=${yearTo}` : '')
			];
			return '/price/average/?' + params.join('&');
		},
		urlSearch() {
			const { mark, model, yearFrom, yearTo } = this.data.payload;
			return `/search/?category_id=1&marka_id=${mark}&model_id=${model}&city[0]=0&state[0]=0&s_yers[0]=${yearFrom}&po_yers[0]=${yearTo}&price_ot=&price_do=`;
		}
	},
	mounted() {
		this.init();
	},
	methods: {
		...mapActions({
			getAveragePrice: 'feed/getAveragePrice'
		}),
		init() {
			this.getAveragePrice({ ...this.data.payload, langId: this.langId })
				.then((graphQlData) => {
					if (graphQlData &&
						graphQlData.price?.prices?.uah &&
						graphQlData.brand?.name &&
						graphQlData.model?.name &&
						graphQlData.price?.bidCount) {

						this.details = graphQlData;
						this.isLoadedData = true;
					} else {
						throw new Error('AveragePrice: failed get full data @vue#17B0B190230');
					}
				})
				.catch((error) => {
					console.error(error);
					this.$emit('ItemError', { type: 'AveragePrice', feedItem: this });
				});
		},
		sendSlonData(eventId, action_block) {
			if (!this.isLoadedData) {
				return;
			}
			this._slonik({
				event_id: eventId,
				action_block,
				transport_type_id: this.details?.brand?.category,
				body_id: this.data?.payload?.body,
				marka_id: this.data?.payload?.mark,
				model_id: this.data?.payload?.model,
				year_from: this.data?.payload?.yearFrom,
				year_to: this.data?.payload?.yearTo,
				price: this.details?.price?.prices?.usd,
				recommendation_version: this.version,
				item_type: 21,
				screentype: this.isDesktop ? 1 : 2,
				owner_id: this.userData?.userId,
				user_id: this.userData?.userId,
				web_id: this.userData?.webClientId,
				position: this.index
			});
		}
	}
};
