export default {
	messages: {
		ru: {
			'mainPage.filters.all': 'Все',
			'common.viewed': 'Просмотренные',
			'common.atFirst': 'Сначала',
			'html.buAuto.vuberite': 'выберите',
			"ТОП моделі": 'ТОП модели',
			'Усі моделі': 'Все модели',
		},
		uk: {
			'mainPage.filters.all': 'Усі',
			'common.viewed': 'Переглянуті',
			'common.atFirst': 'Спочатку',
			'html.buAuto.vuberite': 'оберіть',
			"ТОП моделі": 'ТОП моделі',
			'Усі моделі': 'Усі моделі',
		},
	}
};
