import {mapActions, mapGetters} from 'vuex';

const item = {
	id: 4,
	main: {params: {catalog_ranges: 4, exclude_rubric_id: 178, project_id: 1}},
};

const CATALOG_TOGGLE = 'mainpageCatalogsNewsSearch';

export default {
	props: ['idActive', 'idSubActive'],
	init({store}) {
		return Promise.all([
			store.dispatch('news/fetch', Object.assign({_target: CATALOG_TOGGLE}, item.main.params)),
		].map(item => item.catch(() => console.error('init. Ошибка при запросе данных для каталогов. Новости'))))
	},
	computed: {
		...mapGetters({
			langId: 'lang/id',
			catalogs: 'news/toggle',
		}),
		list() {
			const {rubricCatalog: catalog = []} = this.catalogs(CATALOG_TOGGLE) || {};
			return catalog.filter((item = {}) => item.is_visible)
		},
		mainList() {
			return this.list.slice(3)
		},
		sideList() {
			return this.list.slice(0, 3)
		},
	},
	watch: {
		langId() {
			this.init();
		},
	},
	methods: {
		...mapActions({
			fetch: 'news/fetch',
		}),
		getData(data = {}, _target = '') {
			this.fetch(Object.assign({_target}, data.params))
		},
		init() {
			this.getData(item.main, CATALOG_TOGGLE);
		},
	},
	mounted() {
		this.init()
	},
}
;
