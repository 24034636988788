import {mapActions, mapGetters} from 'vuex';

export default {
	i18n: require('./i18n').default,
	data() {
		return {
		};
	},
	mounted() {
		this.setWithVinPercent();
	},
	computed: {
		...mapGetters({
			withVinPercent: 'others/withVinPercent',
		})
	},
	methods: {
		...mapActions({
			setWithVinPercent: 'others/withVinPercent'
		})
	}
};
