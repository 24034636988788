import {mapGetters} from 'vuex';

export default {
	i18n: require('./i18n').default,
	data() {
		return {
			wasInView: false,
		};
	},
	computed: {
		...mapGetters({
			userData: 'Common/userData'
		}),
	},
	methods: {
		onClickTelegram() {
			this.gaEvent('MainPageNew', 'ClickOn_TelegramBOT', 'bu_Social_networking_service');
			const {userId, webClientId} = this.userData || {};

			this._slonik({
				project_id: 1,
				event_id: 463,
				screentype: this.isDesktop ? 1 : 2,
				owner_id: userId,
				web_id: webClientId,
				subscription_search_channel: 3
			});
		},
		onClickGarage() {
			this._slonik({
				event_id: 1384,
				action_block: 1,
				request_type: 2,
				click_action: 1,
				screentype: this.isDesktop ? 1 : 2,
			});
		}
	},
	mounted() {
		const _this = this;
		try {
			const IOW = require('../../../helpers/IntersectionObserver_screen_333_01');

			IOW.add(this.$el, (entry = {}) => {
				if (entry.isIntersecting) {
					_this.wasInView = true;
					IOW.del(entry.target);
				}
			});
		} catch (error) {
			console.error(error);
			_this.wasInView = true;
		}
	}
};
