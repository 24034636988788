import { mapActions, mapGetters } from 'vuex';
import statistics from './statistics'

export default {
	props: {
		showFastSearch: {
			type: Number
		},
	},
	data(){
		return {statistics}
	},
	init({store}) {
		return Promise
			.all([
				store.dispatch('others/countersPerDayHour'),
				store.dispatch('others/countersTotal'),
				store.dispatch('others/fetchFastSearchLinks'),
			].map(item => item.catch((error) => console.error(error.message)))
		);
	},
	computed: {
		...mapGetters('others', {
			countersPerDayHour: 'countersPerDayHour',
			countersTotal: 'countersTotal',
			fastSearchLinks: 'fastSearchLinks',
		}),
		links() {
			return {
				main: this.fastSearchLinks.slice(0, 3),
				second: this.fastSearchLinks.slice(3),
			}
		},
		countersPerDayHourSafe() {
			return this.countersPerDayHour || {}
		},
		countersTotalSafe() {
			return this.countersTotal || {}
		},
	},
	methods: {
		...mapActions('others', {
			getCountersPerDayHour: 'countersPerDayHour',
			getCountersTotal: 'countersTotal',
		}),
		init() {
			this.getCountersPerDayHour();
			this.getCountersTotal({disable_gzip: true});
			this.$emit('logsElevator', {
				eventId: 18,
				fastSearch: this.showFastSearch === 1 ? this.fastSearchLinks.map((obj) => obj.linkId) : undefined,
				dataEvent: 1,
				typeTabForGA: 'all'});
		},
		logOnClick(data) {
			this.$emit('logsElevator', {eventId: 922, fastSearch: data});
		},
	},
	mounted(){
		this.init();
	},
	i18n: require('./i18n').default,
};
