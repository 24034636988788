export default {
	messages: {
		ru: {
			'html.buAuto.novui': 'новый',
			'common.thousandKm': 'тыс. км',
			'Перевірені авто важливіші,': 'Проверенные авто важнее,',
			'ніж з рекламою': 'чем с рекламой',
			'продавців': 'продавцов',
			'на AUTO.RIA вже перевірили свої авто. Відтепер найвище у пошуку тільки перевірені!': 'на AUTO.RIA уже проверили свои авто. Теперь выше в поиске только проверенные!',
			'Ви маєте право знати,': 'Вы имеете право знать,',
			'що купуєте.': 'что покупаете.',
			'Детальніше': 'Подробнее',
		},
		uk: {
			'html.buAuto.novui': 'новий',
			'common.thousandKm': 'тис. км',
			'Перевірені авто важливіші,': 'Перевірені авто важливіші,',
			'ніж з рекламою': 'ніж з рекламою',
			'продавців': 'продавців',
			'на AUTO.RIA вже перевірили свої авто. Відтепер найвище у пошуку тільки перевірені!': 'на AUTO.RIA вже перевірили свої авто. Відтепер найвище у пошуку тільки перевірені!',
			'Ви маєте право знати,': 'Ви маєте право знати,',
			'що купуєте.': 'що купуєте.',
			'Детальніше': 'Детальніше',
		},
	}
};
