export default {
    messages: {
        ru: {
            'Отзыв о': 'Отзыв о',
            'Читать отзыв о': 'Читать отзыв о',
            '5': 'отлично',
            '4': 'хорошо',
            '3': 'посредственно',
            '2': 'плохо',
            '1': 'ужасно',
            '0': ' ',
        },
        uk: {
            'Отзыв о': 'Відгук про',
            'Читать отзыв о': 'Читати відгук про',
            '5': 'відмінно',
            '4': 'добре',
            '3': 'посередньо',
            '2': 'погано',
            '1': 'жахливо',
            '0': ' ',
        }
    }
};
