import {mapActions} from 'vuex';

const {MODES, FEED_ITEM_TYPES} = require('./constants');
const query = require('./query');
const mutateBlockFeedItem = require('./mutateBlockFeedItem');
// todo: temporarily for test_new_features
import {get} from '../../../../helpers/cookie';

const componentMap = {
	UsedAuto: FEED_ITEM_TYPES.USED_AUTO,
	NewAuto: FEED_ITEM_TYPES.NEW_AUTO,
	News: FEED_ITEM_TYPES.NEWS,
	CompareCar: FEED_ITEM_TYPES.COMPARE_CAR,
	AveragePrice: FEED_ITEM_TYPES.AVERAGE_PRICE,
	CarCheck: FEED_ITEM_TYPES.VIN_BANNER,
	CarCheckSelection: FEED_ITEM_TYPES.VIN_ROTATOR,
};

let isItFirstTime = true;

export default {
	i18n: require('./i18n').default,
	props: ['item'],
	data() {
		return ({
			MODES,
			mode: MODES.OPENED
		});
	},
	computed: {
		id() {
			const {item: {payload: {id} = {}} = {}} = this;
			return id;
		},
		payload() {
			const {item: {payload = {}} = {}} = this;
			return payload;
		},
		type() {
			const {item: {type} = {}} = this;
			return FEED_ITEM_TYPES[componentMap[type]];
		}
	},
	methods: {
		...mapActions({
			blockFeedItem: 'feed/blockFeedItem'
		}),
		onClickShowCauses() {
			if (isItFirstTime) {
				this.mode = MODES.CAUSE;
			} else {
				this.onClickHide();
			}
		},
		onClickHide() {

			if (isItFirstTime) {
				isItFirstTime = false;
				this.mode = MODES.HIDDEN;
			} else {
				this.mode = MODES.HIDDEN_COMPLETELY;
			}

			let data;
			if (Number(get('test_new_features')) === 4242) {
				if (!this.type) {
					throw new Error('onClickHide >> empty field type @vue#17ACD5004DA');
				}
				const content = { ...this.payload };
				delete content.__typename;
				data = {
					query: mutateBlockFeedItem,
					variables: {
						items: [{
							type: this.type,
							...(this.id && {id: Number(this.id)}),
							...(!this.id && { content })
						}]
					}
				};
			} else {
				data = {query, variables: {items: [{id: this.id, type: this.type}]}};
			}
			this.blockFeedItem(data);
		},
		onClickCancel() {
			this.mode = MODES.OPENED;
		}
	}
};
