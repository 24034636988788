export default {
	messages: {
		ru: {
			'html.buAuto.novui': 'новый',
			'html.buAuto.bezprobega': 'без пробега',
			'common.neukazano': 'Не указано',
		},
		uk: {
			'html.buAuto.novui': 'новий',
			'html.buAuto.bezprobega': 'без пробігу',
			'common.neukazano': 'Не вказано',
		},
	}
};
