import {mapActions, mapGetters} from 'vuex';
import isChildElement from '../../../helpers/isChildElement';

export default {
	i18n: require('./i18n').default,
	metaInfo() {
		return {
			bodyAttrs: {
				class: this.openInterview_ && this.isMobile ? 'modal-open page-overflow' : ''
			}
		}
	},
	data() {
		return {
			openInterview_: false,
			message: '',
			sended: false,
			opened: false
		}
	},
	computed: {
		...mapGetters({
			lastSearchesFilled: 'search/history/lastSearchesFilled',
			countersPerDayHour: 'others/countersPerDayHour',
			countersTotal: 'others/countersTotal',
			userData: 'Common/userData'
		}),
		countersPerDayHourSafe() {
			return this.countersPerDayHour || {};
		},
		countersTotalSafe() {
			return this.countersTotal || {};
		},
	},
	watch: {
		langId() {
			this.fetchLastSearches({limit: 10});
		},
		openInterview_(value) {
			if (value) {
				this.$router.push({hash: 'review'})
			} else {
				this.$router.push({hash: ''})
			}
		}
	},
	methods: {
		...mapActions({
			fetchLastSearches: 'search/history/fetchLastSearches',
			sendToKayako: 'others/sendToKayako',
			subscribe: 'others/subscribe',
		}),
		openInterview() {
			this.openInterview_ = true;
		},
		closeInterview(event) {
			this.openInterview_ = false;
			event.stopPropagation();
			event.preventDefault();
		},
		checkClose(event) {
			if (!isChildElement(this.$refs.popup, event.target)) {
				this.closeInterview();
			}
		},
		submit(event) {
			event.preventDefault();
			this.sended = true;
			const tid = 452;
			const typeSend = 'impressions';
			const subject = 'Отзыв об истории поиска на главной';
			const {userId} = this.userData || {};

			this.subscribe({subject, text: this.message, typeSend, tid, userId});
			return false;
		}
	},
	mounted() {
		this.fetchLastSearches({limit: 10});
		window.addEventListener('popstate', this.closeInterview);
		this.$emit('logsElevator', {
			eventId: 18,
			dataEvent: 1,
			typeTabForGA: 'all'});
	},
	beforeDestroy() {
		window.removeEventListener('popstate', this.closeInterview);
	}
};
