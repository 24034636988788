export default {
	messages: {
		uk: {
			'авто з усієї України': 'авто з усієї України',
			'за годину': 'за годину',
			'за день': 'за день',
			'перевірено по VIN-коду': 'перевірено по VIN-коду',
		},
		ru: {
			'авто з усієї України': 'авто со всей Украины',
			'за годину': 'за час',
			'за день': 'за день',
			'перевірено по VIN-коду': 'перевірено по VIN-коду',
		}
	}
};
