import {mapGetters, mapActions} from 'vuex';

export default {
	i18n: require('./i18n').default,
	data: function () {
		return {};
	},
	computed: {
		...mapGetters({
			regions: 'shared/locations/l10n',
			region: 'shared/locations/region',
			city: 'shared/locations/cityl10n',
			selectedPlace: 'search/state/newAuto/getSpecificLocation'
		}),
		options() {
			let regions = Object.values(this.regions);

			// Забираем Киев из списка
			let kievIndex = regions.findIndex(({value} = {}) => Number(value) === 10);
			const [kiev] = kievIndex !== -1 ? (regions.splice(kievIndex, 1)) : [];
			regions = regions.sort((a, b) => a.name.localeCompare(b.name, 'en'));
			// Делаем Киев первым в списке
			if (kiev) {
				regions.unshift(kiev);
			}
			return regions;
		},
		selected: {
			get: function () {
				const {region = 0} = this.selectedPlace(0) || {};
				return region;
			},
			set: function (value) {
				this.setOnlyRegion(value);
			}
		}
	},
	methods: {
		...mapActions({
			fetch: 'shared/locations/fetchCenters',
			setOnlyRegion: 'search/state/newAuto/setOnlyRegion'
		}),
		init(){
			this.fetch();
		},
		selectRegion(event) {
			const {target: {dataset: {value} = {}} = {}} = event;
			this.setOnlyRegion(value);
			this.hideOptionsList();
		}
	},
	serverPrefetch(){
		this.fetch();
	},
	mounted() {
		this.fetch();
	}
};
