const CONTENT_TYPES = {};
CONTENT_TYPES[CONTENT_TYPES["USED_AUTO"] = 1] = "USED_AUTO";
CONTENT_TYPES[CONTENT_TYPES["NEW_AUTO"] = 2] = "NEW_AUTO";
CONTENT_TYPES[CONTENT_TYPES["NEWS"] = 3] = "NEWS";
CONTENT_TYPES[CONTENT_TYPES["AUTO_COST_ESTIMATION"] = 17] = "AUTO_COST_ESTIMATION";
CONTENT_TYPES[CONTENT_TYPES["AUTO_REVIEW"] = 19] = "AUTO_REVIEW";
CONTENT_TYPES[CONTENT_TYPES["COMPARE_CAR"] = 20] = "COMPARE_CAR";
CONTENT_TYPES[CONTENT_TYPES["AVERAGE_PRICE"] = 21] = "AVERAGE_PRICE";
CONTENT_TYPES[CONTENT_TYPES["VIN_ROTATOR"] = 22] = "VIN_ROTATOR";
CONTENT_TYPES[CONTENT_TYPES["VIN_BANNER"] = 23] = "VIN_BANNER";

exports.default = Object.freeze(CONTENT_TYPES);
