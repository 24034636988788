import {mapActions} from 'vuex';
import RotatorItem from './RotatorItem/index.vue';

export default {
	name: 'RotatorVideoStories',
	data() {
		return {
			pageNum: 0,
			countItemsOnList: 4,
			idsAll: [],
			idsCurrent: [],
			itemsCurrent: undefined,
			pagesItems: {},
			maxPage: 0
		};
	},
	components: {
		'rotator-item': RotatorItem,
	},
	methods: {
		...mapActions({
			rotatorVideoIds_: 'search/advertisement/searchAPI',
			rotatorVideo_: 'rotator/rotatorVideo'
		}),
		setCurrentIds() {
			this.idsCurrent = this.idsAll.slice(this.countItemsOnList * this.pageNum, this.countItemsOnList * (this.pageNum + 1));
			if (this.pagesItems[this.idsCurrent.join('-')]) {
				this.itemsCurrent = this.pagesItems[this.idsCurrent.join('-')];
			} else {
				this.rotatorVideo_({
					ids: this.idsCurrent
				}).then((result) => {
					this.pagesItems[this.idsCurrent.join('-')] = result;
					this.itemsCurrent = result;
				});
			}
		}
	},
	computed: {
		page: {
			get: function () {
				return this.pageNum;
			},
			set: function (value) {
				if (value >= 0 && value <= this.maxPage) {
					this.itemsCurrent = undefined;
					this.pageNum = value;
					this.setCurrentIds();
				}
			},
		},
		isButtonPrevEnabled() {
			return this.page > 0;
		},
		isButtonNextEnabled() {
			return this.page < this.maxPage;
		}
	},
	mounted() {
		this.rotatorVideoIds_({
			'verified.VIN': 1,
			'video_messages': 1,
			'order_by': 14,
			'countpage': 40
		}).then((response) => {
			this.idsAll = response?.result?.search_result?.ids || [];
			this.maxPage = Math.floor(this.idsAll.length / this.countItemsOnList) - 1;
			this.setCurrentIds();
		});
	},
	i18n: {
		messages: {
			uk: {
				'Як це працює': 'Як це працює',
				'Відеоповідомлення': 'Відеоповідомлення',
				'Переглядай авто онлайн': 'Переглядай авто онлайн',
			},
			ru: {
				'Як це працює': 'Как это работает',
				'Відеоповідомлення': 'Видеосообщение',
				'Переглядай авто онлайн': 'Просматривайте авто онлайн',
			}
		}
	}
};
