export default {
	messages: {
		ru: {
			'Порівняйте який автомобіль краще, надійніше і крутіше': 'Сравните какой автомобиль лучше, надежнее и круче',
			'Відмінно': 'Отлично',
			'Порівняти': 'Сравнить',
			'Середня ціна': 'Средняя цена',
			'Детальніше про середню ціну': 'Подробнее о средней цене',
			'Дивитись': 'Смотреть',
			'пропозицій': 'предложений',
			'Виберіть авто для перевірки': 'Выберите авто для проверки',
			'тис': 'тыс',
			'Перевірити': 'Проверить',
			'Перевірте авто до покупки': 'Проверьте авто до покупки',
			'Технічно': 'Технически',
		},
		uk: {
			'Порівняйте який автомобіль краще, надійніше і крутіше': 'Порівняйте який автомобіль краще, надійніше і крутіше',
			'Відмінно': 'Відмінно',
			'Порівняти': 'Порівняти',
			'Середня ціна': 'Середня ціна',
			'Детальніше про середню ціну': 'Детальніше про середню ціну',
			'Дивитись': 'Дивитись',
			'пропозицій': 'пропозицій',
			'Виберіть авто для перевірки': 'Виберіть авто для перевірки',
			'тис': 'тис',
			'Перевірити': 'Перевірити',
			'Перевірте авто до покупки': 'Перевірте авто до покупки',
			'Технічно': 'Технічно',
		}
	}
};
