import {mapActions, mapGetters} from 'vuex';
import preparer from '../../../../helpers/preparer';
import {getMounthNameGenitive} from '../../../../helpers/date';
import {get, set, unset} from '../../../../helpers/cookie';

const EXPIRES = 259200; // три дня
const TOP_PRIOR_DEFAULT = '2,3,4,5,0';

const itemPattern = {
	'fields.eng': {default: ''},
	'_source.photo_additional.photo520_166': {handler: (path = '') => path ? `https://cdn.riastatic.com/docs/${path}` : ''},
	'_source.photo_additional.photo520_166_webp': {handler: (path, result) => result._source.photo_additional.photo520_166 ? result._source.photo_additional.photo520_166.replace(/jpg$/, 'webp') : ''},
	'fields.photo': {default: ''},
	'fields.photo_jpg': {handler: (path, result) => result.fields.photo ? `https://cdn.riastatic.com/photos/${result.fields.photo.replace(/(\.jpg)$/, 'm$1')}` : ''},
	'fields.photo_webp_140x88': {handler: (path, result) => result.fields.photo ? `https://cdn.riastatic.com/photosnewr/ria/news_common/${result.fields.eng}__${result.fields.photo.replace(/.*?(\d+)\.jpg$/, '$1-140x88.webp')}` : ''},
	'fields.description': {default: ''},
	'fields.title': {default: ''},
	'fields.link': {default: ''},
	'fields.news_id': {default: ''},
	'fields.journal': {default: ''},
	'fields.comments_count': {default: ''},
	'fields.add_date_int': {
		default: 0, handler(int, result) {
			let date = new Date(int * 1000);
			if (date instanceof Date) {
				const day = date.getDate();
				const month = date.getMonth();
				const year = date.getFullYear();

				if (year === (new Date()).getFullYear() && month === (new Date).getMonth()) { // сегодня/вчера
					if (day === (new Date()).getDate()) { // сегодня
						result.fields.add_date_formated = this.$t('common.today');
					} else {
						if (day + 1 === (new Date()).getDate()) { // вчера
							result.fields.add_date_formated = this.$t('common.yesterday');
						} else {
							const monthText = getMounthNameGenitive(month);
							result.fields.add_date_formated = `${day} ${this.$t(monthText)} ${year}`;
						}
					}
				} else {
					const monthText = getMounthNameGenitive(month);
					result.fields.add_date_formated = `${day} ${this.$t(monthText)} ${year}`;
				}
			}
			return date
		}
	},
};

function createCookie(news) {
	// устанавливаем новостные cookie (как Вася сказал)
	return news.reduce((result, item = {}, index) => {
		const {
			fields: {
				news_id,
				top_prior
			} = {}
		} = item;
		result.value[`item${index}`] = top_prior;

		return result;
	}, {name: 'news_prior', value: {}});
}

const NEWS_TARGET = 'main';

export default {
	i18n: require('./i18n').default,
	data() {
		return {
			wasInView: false,
			loaded: false
		}
	},
	computed: {
		...mapGetters({
			category: 'shared/categories/category',
			subCategories: 'shared/subCategories/getMain',
			toggle: 'news/toggle'
		}),
		news() {
			const {data} = this.toggle(NEWS_TARGET) || {};
			const news = Array.isArray(data) ?
				data.map(function (item) {
					return preparer(item, itemPattern, this)
				}, this) :
				[];

			return news
		},
		item() { // first item
			let [item] = this.news || [];
			return item;
		},
		others() { // others items
			return this.news.slice(1)
		},
	},
	methods: {
		...mapActions({
			fetchFor: 'news/fetch'
		}),
		fetch(params) {
			this.fetchFor(Object.assign({_target: NEWS_TARGET}, params))
		},
		init() {
			let top_prior = TOP_PRIOR_DEFAULT;
			if (Array.isArray(this.news) && this.news.length) {
				const cookieData = createCookie(this.news);
				const cookieCurrent = get(cookieData.name);
				if (!cookieCurrent) {
					set(cookieData.name, JSON.stringify(cookieData.value), { expires: EXPIRES });
				} else {
					try {
						const items = JSON.parse(cookieCurrent);
						top_prior = Object.values(items).reverse();
						top_prior.push(0);
						top_prior = top_prior.join();
					} catch (error) {
						console.error(error);
					}
				}
			}
			this.fetch({size: 4, top_prior})
		},
		setPriority(index) {
			const news = this.news;
			const cookieData = createCookie(news);
			const cookieCurrent = get(cookieData.name);
			if (cookieCurrent) {
				try {
					cookieData.value = JSON.parse(cookieCurrent);
				} catch (error) {
					console.error(error);
				}
			}
			const itemName = `item${index}`;
			// изменяем значение согласно васиной логики
			let value = cookieData.value[itemName];

			if (value < 10) {
				value += 10;
			} else {
				if (value !== 15) {
					value = 0
				}
			}
			cookieData.value[itemName] = value;
			unset(cookieData.name, { secure: true, sameSite: 'none' });
			set(cookieData.name, JSON.stringify(cookieData.value), { expires: EXPIRES });
		}
	},
	serverPrefetch(){
		return this.fetchFor({_target: NEWS_TARGET, size: 4, top_prior: TOP_PRIOR_DEFAULT})
	},
	mounted() {
		this.init();
		const _this = this;

		try {
			const IOW = require('../../../../helpers/IntersectionObserver_screen_333_01');

			IOW.add(this.$el, (entry = {}) => {
				if (entry.isIntersecting) {
					_this.wasInView = true;
					IOW.del(entry.target);
				}
			});
		} catch (error) {
			console.error(error);
			_this.wasInView = true;
		}
	}
};
