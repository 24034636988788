import {mapActions, mapGetters} from 'vuex';

import rubrics from './config';

export default function (CATALOG_TOGGLE_MAIN, CATALOG_TOGGLE_SIDE, idActive) {
	const {[idActive]: item} = rubrics;

	return {
		i18n: require('./i18n').default,
		init({store, target}) {
			if (target === 'node') {
				const {id, sub = {}} = item;
				return Promise.all(Object.values(sub)
					.map(item => {
						const {id: subId, main, side} = item;
						const promises = [];
						if (main) {
							promises.push(store.dispatch('catalogs/fetch', Object.assign({_target: CATALOG_TOGGLE_MAIN}, item.main.params)));
						}
						if (side) {
							promises.push(store.dispatch('catalogs/fetch', Object.assign({_target: CATALOG_TOGGLE_SIDE}, item.side.params)));
						}
						return Promise.all(promises.map(item => item.catch(() => console.error('init. Ошибка при запросе данных для каталогов. Секция ', 'id:', id, '. sub: id:', subId))));
					}));
			}
		},
		data() {
			return {
				unhiddenChild: undefined,
				gaPrefix: (() => {
					switch (idActive) {
						case 1:
							return 'BU';
						case 2:
							return 'NA';
						case 3:
							return 'AllForAuto';
					}
				})()
			};
		},
		props: ['idSubActive'],
		computed: {
			...mapGetters({
				catalogs: 'catalogs/toggle'
			}),
			mainList() {
				return this.catalogs(CATALOG_TOGGLE_MAIN) || [];
			},
			sideList() {
				return this.catalogs(CATALOG_TOGGLE_SIDE) || [];
			}
		},
		watch: {
			langId() {
				this.init();
			},
			idSubActive() {
				this.init();
			}
		},
		methods: {
			...mapActions({
				fetch: 'catalogs/fetch'
			}),
			getData(data = {}, _target = '') {
				return this.fetch(Object.assign({_target}, data.params));
			},
			init() {
				this.unhiddenChild = undefined;
				const {
					sub: {[this.idSubActive]: {main, side} = {}} = {}
				} = item;
				const promises = [];
				if (main) {
					promises.push(this.getData(main, CATALOG_TOGGLE_MAIN));
				}
				if (side) {
					promises.push(this.getData(side, CATALOG_TOGGLE_SIDE));
				}
				return Promise.all(promises.map(item => {
					return item.catch((e) => {
						console.error('[ERROR] ProductFactory init:', e.message);
					});
				}));
			},
			gotoCatalog(payload) {
				location.href = payload;
			},
			unhideChild(index) {
				this.unhiddenChild = this.unhiddenChild === index ? undefined : index;
			},
			generateHref(item) {
				return `${this.gaPrefix === 'AllForAuto' || item.langPrefixDisabled ? '' : this.langPrefix}${item.link}`;
			}
		},
		serverPrefetch() {
			return this.init();
		}
	};
}
