export default {
    messages: {
        ru: {
            'Оценка стоимости авто': 'Оценка стоимости авто',
            'Узнайте рыночную стоимость продажи авто': 'Узнайте рыночную стоимость продажи авто',
            'Оценить стоимость': 'Оценить стоимость',
        },
        uk: {
            'Оценка стоимости авто': 'Оцінка вартості авто',
            'Узнайте рыночную стоимость продажи авто': 'Дізнайтесь ринкову вартість продажу авто',
            'Оценить стоимость': 'Оцінити вартість',
        }
    }
};
