import {mapActions, mapGetters} from 'vuex';

export default {
	init({store}) {
		return store.dispatch('news/fetchLandingMainLink')
	},
	props: ['idSubActive'],
	watch: {
		langId() {
			return this.init();
		},
	},
	computed: {
		...mapGetters({
			catalogs: 'news/LandingMainLink',
		}),
		catalogsSafe() {
			return Array.isArray(this.catalogs) ? this.catalogs : []
		}
	},
	methods: {
		...mapActions({
			fetch: 'news/fetchLandingMainLink',
		}),
		init() {
			return this.fetch();
		},
	},
	mounted() {
		return this.init()
	},
};
