import {mapActions} from 'vuex';

export default {
	mixins: [require('../../../../mixins/recommendationFeedLogger')],
	i18n: require('./i18n').default,
	props: {
		data: Object,
		index: Number,
		version: String,
		userData: Object
	},
	data() {
		return {
			isLoadedData: false,
			imagesData: {},
			items: [],
		};
	},
	computed: {
		urlTarget() {
			return this.items.length === 2 ? `/compare/${this.items[0].brand?.namePath}_${this.items[0].model?.namePath}_vs_${this.items[1].brand?.namePath}_${this.items[1].model?.namePath}.html` : '#';
		}
	},
	mounted() {
		this.init();
	},
	methods: {
		...mapActions({
			getCompareCarDetailed: 'feed/getCompareCarDetailed'
		}),
		ratingShort: (item) => Math.round(Number((item.review?.avg || 0) * 10)) / 10,
		ratingTranslate: (item) => String(Math.ceil(Number(item.review?.avg || 0)) || 0),
		itemImageUrlSrc: (item) => `https://cdn.riastatic.com/photosnewr/${item.photo?.area}/__${item.photo?.photoId}-341x224.jpg`,
		itemImageUrlSrcset: (item) => `https://cdn.riastatic.com/photosnewr/${item.photo?.area}/__${item.photo?.photoId}-341x224.webp`,
		itemTitle: (item) => `${item.brand?.name || ''} ${item.model?.name || ''}`,
		itemBaseLine: (item) => `${item.model?.baseInfo || ''} ${item.model?.baseYear || ''}`,
		init() {
			this.getCompareCarDetailed({ ...this.data.payload })
				.then((graphQlData) => {
					if (graphQlData.autos?.length) {
						this.items = graphQlData.autos;
						this.isLoadedData = graphQlData.autos.length > 0;
						this.imagesData = graphQlData.autos.reduce((imagesData, item) => {
							if (!item.photo?.photoId) {
								throw new Error('CompareCar: failed get full data for photo in block @vue#17B0B190230');
							}
							imagesData[String(item.photo.photoId)] = { loadedImg: false };
							return imagesData;
						}, {});
					} else {
						throw new Error('CompareCar: failed get full data for photo in block @vue#17B0B806AEC');
					}
				})
				.catch(error => {
					this.$emit('ItemError', { type: 'CompareCar', feedItem: this });
					console.error(error);
				});
		},
		sendSlonData(eventId) {
			if (!this.isLoadedData) {
				return;
			}
			this._slonik({
				event_id: eventId,
				marka_id: this.items.map(item => item.brand?.id),
				model_id: this.items.map(item => item.model?.id),
				year: this.items.map(item => item.model?.baseYear),
				power: this.items.map(item => item.model?.power),
				engine_capacity: this.items.map(item => item.model?.volume),
				sd_rating: this.items.map(item => item.review?.avg),
				recommendation_version: this.version,
				item_type: 20,
				screentype: this.isDesktop ? 1 : 2,
				owner_id: this.userData?.userId,
				user_id: this.userData?.userId,
				web_id: this.userData?.webClientId,
				position: this.index
			});
		},
		isLoadedImg(auto) {
			return this.imagesData[String(auto.photo.photoId)].loadedImg;
		},
		onItemImgLoaded(auto) {
			this.imagesData[String(auto.photo.photoId)].loadedImg = true;
		},
		onItemImgFailed(item) {
			this.$emit('ItemError', { type: 'CompareCar', feedItem: this });
		}
	}
};
