export default {
	messages: {
		ru: {
			'html.buAuto.katalogavtomobilei_1': 'Каталог автомобилей',
			'html.buAuto.byavto': 'Б/у авто',
			'html.buAuto.novueavto': 'Новые авто',
			'common.allMotorists': 'Все для автомобилиста',
			'html.buAuto.novosti': 'Новости',
			'common.topic': 'Темы',
			'html.buAuto.otzuvu': 'Отзывы',

			'html.buAuto.pomarke': 'по марке',
			'html.buAuto.poregiony': 'по региону',
			'html.buAuto.potipy': 'по типу',
			'html.buAuto.poprivodu': 'по приводу',
			'html.buAuto.pofuel': 'по топливу',

			'common.sort': 'сортировать',
			'mainPage.services.spares': 'Запчасти',
		},
		uk: {
			'html.buAuto.katalogavtomobilei_1': 'Каталог автомобілів',
			'html.buAuto.byavto': 'Вживані авто',
			'html.buAuto.novueavto': 'Нові авто',
			'common.allMotorists': 'Все для автомобіліста',
			'html.buAuto.novosti': 'Новини',
			'common.topic': 'Теми',
			'html.buAuto.otzuvu': 'Відгуки',

			'html.buAuto.pomarke': 'по марці',
			'html.buAuto.poregiony': 'по регіону',
			'html.buAuto.potipy': 'по типу',
			'html.buAuto.poprivodu': 'по приводу',
			'html.buAuto.pofuel': 'по паливу',

			'common.sort': 'сортувати',
			'mainPage.services.spares': 'Запчастини',
		},
	}
};
