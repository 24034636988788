import {mapGetters, mapActions} from 'vuex';

import {YearsRange} from '../../../../../helpers/Range';

export default {
	i18n: require('./i18n').default,
	data: function () {
		return {}
	},
	computed: {
		...mapGetters({
			getSpecificYears: 'search/state/getSpecificYears'
		}),
		list: function () {
			const now = new Date;
			const upperYear = now.getMonth() < 11 ?
				now.getFullYear() :
				now.getFullYear() + 1; // Привет, DeLorean =)

			const range = new YearsRange(1900, upperYear);
			return Array.from(range).reverse();
		},
		from: {
			get: function () {
				const {from: value = 0} = this.getSpecificYears(0) || {};
				return value
			},
			set: function (value) {
				value = parseInt(value);
				let from = Number.isFinite(value) && value > 0 ? value : undefined;
				let {to} = this.getSpecificYears(0) || {};
				if (to < from) {
					[to, from] = [from, to];
				}
				this.setOnlyYears({from, to})
			}
		},
		to: {
			get: function () {
				const {to: value = 0} = this.getSpecificYears(0) || {};
				return value
			},
			set: function (value) {
				value = parseInt(value);
				let to = Number.isFinite(value) && value > 0 ? value : undefined;
				let {from} = this.getSpecificYears(0) || {};
				if (to < from) {
					[to, from] = [from, to];
				}
				this.setOnlyYears({from, to})
			}
		}
	},
	methods: {
		...mapActions({
			setOnlyYears: 'search/state/setOnlyYears'
		}),
	}
};
