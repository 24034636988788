import collector from '../../../../helpers/collector';
import _laxios from '../../../../store/_laxios';
const newAuto = import('./newAuto.graphql');

const pattern = {
	type: (value = '') => value,
	'payload.id': (value) => value,
	'payload.brand.name': (value = '') => value,
	'payload.brand.eng': (value) => value || '',
	'payload.brand.id': (value) => value || 0,
	'payload.model.name': (value = '') => value,
	'payload.model.eng': (value) => value || '',
	'payload.model.id': (value) => value || 0,
	'payload.year': (value) => value,
	'payload.uri': (value) => value,
	'payload.fuel.name': (value) => value,
	'payload.gearbox.name': (value) => value,
	'payload.location.city.name': (value) => value,
	'payload.location.city.id': (value) => value,
	'payload.autotest.vinPackage': ({freeCheck} = {}) => freeCheck,
	'payload.price.main.value': (value) => Number.isFinite(Number(value)) ? Number(value).toLocaleString('ru', {style: 'decimal'}) : value,
	'payload.price.main.currency.id': (value) => value,
	'payload.price.main.currency.sign': (value) => value,
	'payload.price.main.currency.abbreviated': (value) => value,
	'payload.price.all.UAH.value': (value) => value,
	'payload.price.all.UAH.currency.id': (value) => value,
	'payload.price.all.UAH.currency.abbreviated': (value) => value,
	'payload.photos.all.0.id': (value) => value,
	'payload.photos.main.id': (value) => value,
	'payload.photos.main.url': (value) => value || '',
	'payload.photos.all.0.url': (value) => value || '',
	'photo': (value) => (value || {}),
	'photo.suffix': (value) => value || '',
	__photo: (value, {payload: {photos: {all: {0: {url: urlFirst} = {}} = {}, main: {url} = {}} = {}} = {}}) => {
		url = url || urlFirst;
		return url ? `https://cdn.riastatic.com/photosnewr/${url}` : '';
	},
	__title: (value, {payload: {brand: {name: brand} = {}, model: {name: model} = {}, year} = {}}) => {
		return `${brand || ''} ${model || ''} ${year || ''}`;
	},
	__link: (value, {payload: {uri} = {}} = {}) => uri,
	__fixer(value, {payload: {id} = {}, type, __photo}) {
		// Убираем авто без фото
        if (id && !__photo) {
			this.$emit('ImageError', Object.assign(new Error('No photo'), {id, type}));
		}
	}
};

function fetchData({langId = 2, id} = {}) {
	// return Promise.resolve({"data":{"newAuto":[{"id":"1855283","brand":{"name":"Opel","eng":"opel","id":"56"},"model":{"name":"Insignia","eng":"insignia","id":"3121"},"year":2019,"location":{"city":{"name":"Хмельницкий","id":"4"},"state":{"name":"Хмельницкий","id":"4"}},"photos":{"main":null,"all":[{"url":"auto/new_auto_storage/opel-insignia__1114006","id":"1114006"},{"url":"auto/new_auto_storage/opel-insignia__1113932","id":"1113932"},{"url":"auto/new_auto_storage/opel-insignia__1114002","id":"1114002"},{"url":"auto/new_auto_storage/opel-insignia__1114024","id":"1114024"},{"url":"auto/new_auto_storage/opel-insignia__1114039","id":"1114039"}]},"price":{"all":{"USD":{"value":33386,"currency":{"id":"1","sign":"$"}}}},"uri":"/newauto/auto-opel-insignia-1855283.html"}]}})
	return newAuto
		.then(({default: query}) => query)
		.then((query) => {
			const variables = {ids: [id], langId};
			return _laxios.graphql
				.request({data: {query, variables}})
				.catch((error) => {
					const message = `[GRAPH_QL API] feed newAuto: ${JSON.stringify(variables)}`;
					error.message = error?.message ? message + '\n' + error.message : message;
					throw error;
				});
		})
		.then(({data: {newAuto} = {}} = {}) => newAuto);
}

export default {
	mixins: [require('../../../../mixins/recommendationFeedLogger')],
	props: ['data', 'index', 'version', 'userData'],
	i18n: require('./i18n').default,
	data() {
		return {
			failedWebp: false,
			loadedImg: false,
			loadedData: false,
		};
	},
	computed: {
		item() {
			if (!this.loadedData) {
				return collector({}, pattern, this);
			} else {
				return collector(this.data, pattern, this);
			}
		}
	},
	mounted() {
		this.init();
	},
	methods: {
		init() {
			return fetchData({langId: this.langId, id: this.data.payload.id})
				.then((response = {}) => {
					let [data] = response;
					this.data.payload = data;
					this.loadedData = true;
				})
				.catch(console.error);
		},
		sendSlonData(eventId) {
			if (this.item.payload && this.item.payload.id) {
				const {item: {photo: {suffix = ''} = {}} = {}} = this;
				this._slonik({
					project_id: 1,
					event_id: eventId,
					proposal_id: this.item.payload.id,
					recommendation_version: this.version,
					screentype: this.isDesktop ? 1 : 2,
					item_type: 2,
					photo_id: this.item.payload.photos.all['0'].id,
					position: this.index,
					photo_suffix: suffix,
					a_b_test_version: suffix.length > 0 ? 1 : 0,
				});
				return true;
			} else {
				return false;
			}
		},
		click() {
			this.gaEvent('MainPageNew', `Lenta_Position${this.index}_Na`, 'Lenta');
			this.sendSlonData(235);
		},
		loaded() {
			this.loadedImg = true;
		},
		failed() {
			if (this.failedWebp) {
				this.$emit('ImageError', {id: this.item.payload.id, type: this.item.type});
			} else {
				this.failedWebp = true;
			}
		}
	}
};
