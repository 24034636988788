<style>.label-close{float:right}._size30{margin:104px 0 5px;font-size:18px;line-height:1;display:block}.popup_promo_add,.popup_promo_add .wrapper,.closePromoBg{position:absolute;bottom:0;left:0;right:0}.popup_promo_add{background-color:rgba(0,0,0,0.6);position:fixed;top:0;z-index:10}.popup_promo_add .wrapper{background-color:#fff;padding:15px}.closePromoBg{top:0}.popup_promo_add .img-car_{background:url(https://cdn.riastatic.com/docs/pictures/common/1/1/10067/10067.png) 0 0 no-repeat;background-size:215px 181px;width:215px;display:inline-block;top:-66px;left:0;position:absolute;height:181px}.popup_promo_add .button-add,.popup_promo_add .btn-blue{width:100%;margin-top:15px}.popup_promo_add .button-add{line-height:40px}.popup_promo_add .btn-blue{line-height:24px;text-align:center}.check_promo_add:checked + .popup_promo_add{display:none}</style>
<template>
    <div>
        <input class="check_promo_add" type="checkbox" id="openPromoAdd" name="">
        <div class="popup_promo_add dhide">
            <label for="openPromoAdd" class="closePromoBg"></label>
            <div class="wrapper">
                <label for="openPromoAdd" class="label-close"><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5 5L15 15M15 5L5 15" stroke="#256799"></path></svg></label>
                <i class="img-car_"></i>

                <strong class="red _size30">Сьогодні додав – завтра продав</strong>
                За один день продається кожне 10 авто на AUTO.RIA

                <a :href="`${langPrefix}/add_auto.html`" class="button-add"><svg class="svg svg-add-deck"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg_header_add_deck"></use></svg> Продати авто</a>

                <label for="openPromoAdd" class="btn-blue link">Закрити</label>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
      name: 'PromotionalCampaign'
    }
</script>
