export default {
	1: {
		id: 1,
		sub: {
			1: {
				id: 1,
				main: {params: {type: 'bu', filter: 'marks', subFilter: 'group', size: 36}},
				side: {params: {type: 'bu', filter: 'marks', subFilter: 'primary', size: 36}},
			},
			2: {
				id: 2,
				main: {params: {type: 'bu', filter: 'cities', subFilter: 'primary', size: 36}},
				side: {params: {type: 'bu', filter: 'marks', subFilter: 'primary', size: 36}}
			},
			3: {
				id: 3,
				main: {params: {type: 'bu', filter: 'categories'}}
			},
			4: {
				id: 4,
				main: {params: {type: 'bu', filter: 'fuel'}}
			}
		}
	},
	2: {
		id: 2,
		sub: {
			1: {
				id: 1,
				main: {params: {type: 'new', filter: 'marks', subFilter: 'group', size: 36}},
				side: {params: {type: 'new', filter: 'marks', subFilter: 'primary', size: 36}}
			},
			2: {
				id: 2,
				main: {params: {type: 'new', filter: 'cities', subFilter: 'primary', size: 36}},
				side: {params: {type: 'new', filter: 'marks', subFilter: 'primary', size: 36}}
			},
			3: {
				id: 3,
				main: {params: {type: 'new', filter: 'categories'}}
			}
		},
	},
	3: {
		id: 3,
		sub: {
			1: {
				id: 1,
				main: {params: {type: 'ria', subFilter: 'marks'}},
				side: {params: {type: 'ria', subFilter: 'primary'}}
			},
			2: {
				id: 2,
				main: {params: {type: 'ria', subFilter: 'region'}},
				side: {params: {type: 'ria', subFilter: 'primary'}},
			},
			3: {
				id: 3,
				main: {params: {type: 'ria', subFilter: 'spares'}},
			}
		}
	},
};
