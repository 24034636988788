import loopify from './loopify';

module.exports = function (a, i1, i2) {
	if (Array.isArray(a)) {
		const result = [];
		for (let i = i1; i <= i2; i++) {
			result.push(a[loopify(i, a.length || 1)]);
		}
		return result;
	} else {
		throw new Error('First argument must be an array')
	}
};
