import { mapGetters } from 'vuex';

const i18n = require('./i18n').default;
const UsedAuto = require('./UsedAuto/index.vue').default;
const NewAuto = require('./NewAuto/index.vue').default;
const Toggle = require('./Toggle/index.vue').default;
const Banner = require('../Banner/index.vue').default;

export default {
  name: 'SearchForm',
  i18n,
  props: ['target', 'withVin'],
  data() {
    return ({
      type: 'all',
      tabRedForm: {
        all: 1,
        bu: 2,
        na: 3,
        order: 4,
      },
    });
  },
  computed: {
    ...mapGetters({
      mainBanner: 'infoBlock/main/mainBannerData',
    }),
  },
  components: {
    'vue-used-auto': UsedAuto,
    'vue-new-auto': NewAuto,
    'vue-toggle': Toggle,
    'vue-banner': Banner,
  },
  async init({ store }) {
    await Promise.all([
      store.dispatch('infoBlock/main/fetchMainBanner'),
      store.dispatch('infoBlock/main/fetchMainBRK'),
    ]);
  },
  methods: {
    onToggleInput(value) {
      this.type = value;
      this.$emit('stats-red-form', { eventId: 541, dataEvent: this.tabRedForm[value] });
    },
  },
};
