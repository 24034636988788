export default {
	messages: {
		ru: {
			'тыс. км': 'тыс. км',
			'Сегодня': 'Сегодня',
			'Вчера': 'Вчера'
		},
		uk: {
			'тыс. км': 'тис. км',
			'Сегодня': 'Сьогодні',
			'Вчера': 'Вчора'
		}
	}
};
