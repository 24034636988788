import {get, set, cloneDeep} from '../../helpers/_lodash';

/**
 * Подготавливает объект для шаблонизатора
 * @param {Object} source
 * @param {{key:String, default:*, handler:Function, args:Array}[]} pattern
 * @param {String} pattern.key - key path
 * @param {*} pattern.default - default value for key
 * @param {Function} pattern.handler - function to handle value in key
 * @param {Array} pattern.args - arguments for handler
 * @param {*} thisArg - context for items `handler`
 * @return {Object}
 */
export default function (source = {}, pattern = {}, thisArg) {
	return Object.keys(pattern).reduce((result, key) => {
		let item = pattern[key] || {};
		const {'default': valueDefault, handler, args = []} = item;
		const value = get(result, key, valueDefault);
		set(
			result, key,
			handler instanceof Function ?
				handler.apply(thisArg, [value, result, ...args]) :
				value
		);

		return result;
	}, cloneDeep(source));
}
