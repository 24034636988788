export default {
	mixins: [require('../../../../mixins/recommendationFeedLogger')],
	i18n: {
		messages: {
			ru: {
				'Порівняйте який автомобіль краще, надійніше і крутіше': 'Сравните какой автомобиль лучше, надежнее и круче',
				'Відмінно': 'Отлично',
				'Порівняти': 'Сравнить',
				'Середня ціна': 'Средняя цена',
				'Детальніше про середню ціну': 'Подробнее о средней цене',
				'Дивитись': 'Смотреть',
				'пропозицій': 'предложений',
				'Виберіть авто для перевірки': 'Выберите авто для проверки',
				'тис': 'тыс',
				'Перевірити': 'Проверить',
				'Перевірте авто до покупки': 'Проверьте авто до покупки',
				'Технічно': 'Технически',
			},
			uk: {
				'Порівняйте який автомобіль краще, надійніше і крутіше': 'Порівняйте який автомобіль краще, надійніше і крутіше',
				'Відмінно': 'Відмінно',
				'Порівняти': 'Порівняти',
				'Середня ціна': 'Середня ціна',
				'Детальніше про середню ціну': 'Детальніше про середню ціну',
				'Дивитись': 'Дивитись',
				'пропозицій': 'пропозицій',
				'Виберіть авто для перевірки': 'Виберіть авто для перевірки',
				'тис': 'тис',
				'Перевірити': 'Перевірити',
				'Перевірте авто до покупки': 'Перевірте авто до покупки',
				'Технічно': 'Технічно',
			}
		}
	},
	props: {
		data: Object,
		index: Number,
		version: String,
		userData: Object
	},
	methods: {
		sendSlonData(eventId) {
			this._slonik({
				event_id: eventId,
				item_type: 23,
				screentype: this.isDesktop ? 1 : 2,
				owner_id: this.userData?.userId,
				user_id: this.userData?.userId,
				web_id: this.userData?.webClientId,
				position: this.index
			});
		}
	}
};
