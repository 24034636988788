export default {
	messages: {
		ru: {
			'news.HowToPrepareCars': 'Как подготовить авто к зиме',
			'news.HowToPrepareCarsSpring': 'Как подготовить авто к весне',
			'common.car.adjective': 'Автомобильные',
			'html.buAuto.novosti_1': 'новости',
			'common.testDrives.definition': 'Тест-драйвы',
			'common.journal': 'Журнал',
			'common.stock': 'Акции',
			'common.prize': 'Премия',
			'news.inspections': 'Обзоры',
		},
		uk: {
			'news.HowToPrepareCars': 'Як підготувати авто до зими',
			'news.HowToPrepareCarsSpring': 'Як підготувати авто до весни',
			'common.car.adjective': 'Автомобільні',
			'html.buAuto.novosti_1': 'новини',
			'common.testDrives.definition': 'Тест-драйви',
			'common.journal': 'Журнал',
			'common.stock': 'Акції',
			'common.prize': 'Премія',
			'news.inspections': 'Огляди',
		},
	}
};
