export default {
	messages: {
		ru: {
			'html.buAuto.vodnui': 'Водный',
		},
		uk: {
			'html.buAuto.vodnui': 'Водний',
		},
	}
};
