import {mapGetters} from 'vuex';

const TARGET = 'first_main_page';

export default {
	data() {
		return {
			item: false,
		}
	},
	computed: {
		...mapGetters({
			getSeoData: 'shared/seo/getTargetData',
			langId: 'lang/id',
		}),
		seo() {
			return this.getSeoData(TARGET) || {}
		},
	},
	watch:{
		langId(){
			this.item = undefined
		}
	},
	methods: {
		toggleFolded() {
			if (!this.item) {
				try {
					([this.item] = this.$refs.root.getElementsByClassName('hide'))
				} catch (error) {
					console.error(error);
				}
			}
			try {
				this.item.classList.toggle("hide");
			} catch (error) {
				console.error(error);
			}
		}
	}
};
