import Vue from 'vue';
import {mapGetters} from 'vuex';

import loopSlice from '../../../../app/helpers/loopSlice';
import _laxios from "../../../store/_laxios";

const CONTENT_TYPES = require("../Feed/Leleka_content_types").default;
const ITEMS_PER_PAGE = 5;

const project = require("./projection").default;

const feed = import('./recommendations.graphql');

function fetchFeed(viewed, {langId = 2, first = ITEMS_PER_PAGE, after, version = ''} = {}) {
	return feed
		.then(({default: query}) => query)
		.then((query) => {
			const variables = {viewed, langId, first, after, version, types: ['USED_AUTO', 'NEW_AUTO']};
			return _laxios.graphql
				.request({data: {query, variables}})
				.catch((error) => {
					console.error('[GRAPH_QL API] recommendations', variables);
					console.error(error);
					throw error;
				});
		})
		.then(({data: {recommendations} = {}} = {}) => recommendations);
}

const intersectionTreshold = {
	rotatorMainPhoto: { // main Item rotator cutted
		out: 0.05,
		on: 0.95
	},
	default: {
		out: 0,
		on: 1
	}
};

export default {
	i18n: require('./i18n').default,
	props: ['watched'],
	data() {
		return {
			hasNextPage: true,
			listRaw: [],
			loadingList: false,
			mounted: false,
			loaded: {},
			page: 0,
			failedWebp: {},
			showInfoBlock: false,
			touch: {x: 0},
			rotatorTop: 0,
			version: '',
			rotatorBottom: 0,
			heightPage: document.documentElement.clientHeight,
			mapSendEvent565: {},
			changePageRotator: false
		};
	},
	computed: {
		...mapGetters({
			userData: 'Common/userData'
		}),
		canFetchItem() {
			return this.hasNextPage && !this.loadingList;
		},
		onLastPage() {
			return this.page + 1 >= Math.floor(this.listRaw.length / ITEMS_PER_PAGE);
		},
		isButtonPrevEnabled() {
			return this.page > 0;
		},
		isButtonNextEnabled() {
			return !this.onLastPage || this.canFetchItem;
		},
		watchedParsed() {
			return Array.isArray(this.watched) ?
				this.watched.map((item) => {
					const [type, id] = typeof item === 'string' ? item.split(':') : [];
					return {id, type: CONTENT_TYPES[type]};
				})
				.filter(({type} = {}) => Boolean(type)) : [];
		},
		rotatorList() {
			return Array.isArray(this.listRaw) ? this.listRaw : [];
		},
		rotatorListSlice() {
			const first = ITEMS_PER_PAGE * this.page;
			const last = ITEMS_PER_PAGE * (this.page + 1) - 1;
			return loopSlice(this.rotatorList, first, last);
		},
		item() {
			return project(this.rotatorListSlice[0]);
		},
		items() {
			let items = this.rotatorListSlice.slice(1);
			// Нужно чтоб в ротаторе при загрузке были 4 заглушки
			if (this.isDesktop && items.length < ITEMS_PER_PAGE - 1) {
				items.length = ITEMS_PER_PAGE - 1;
				items = Array.from(items, (item = {}) => item);
			}

			if (items.length > 2) {
				[items[2], items[3]] = [items[3], items[2]];
			}

			return items.map((item) => project(item));
		},
	},
	methods: {
		onPrev() {
			if (!this.isButtonPrevEnabled) return;
			this.page--;
		},
		onNext() {
			if (!this.isButtonNextEnabled) return;
			if (this.onLastPage && this.canFetchItem) {
				const [{cursor: after}] = this.listRaw.slice(-1);
				this.loadingList = true;
				return fetchFeed(this.watchedParsed, {
					first: ITEMS_PER_PAGE + 2,
					langId: this.langId,
					version: this.version,
					after
				})
					.then((response = {}) => {
						let {edges, PageInfo: {hasNextPage} = {}} = response;
						this.hasNextPage = hasNextPage;
						const feed = Array.isArray(edges) ? edges : [];
						this.listRaw.splice(this.listRaw.length, 0, ...feed);
					})
					.then(() => {
						this.loadingList = false;
						this.page++;
					});
			} else {
				this.page++;
			}
		},
		initRecommendations() {
			return fetchFeed(this.watchedParsed, {first: ITEMS_PER_PAGE + 2, langId: this.langId, version: this.version})
				.then((response = {}) => {
					let {edges, version, PageInfo: {hasNextPage} = {}} = response;
					this.hasNextPage = hasNextPage;
					this.version = version;
					const feed = Array.isArray(edges) ? edges : [];
					this.listRaw.splice(0, this.listRaw.length, ...feed);
				});
		},
		touchstart(event) {
			const {changedTouches: {0: {pageX: x} = {}} = {}} = event;
			this.touch.x = x;
		},
		touchend(event) {
			const {changedTouches: {0: {pageX: x} = {}} = {}} = event;
			if (this.touch.x - x > 50) {
				this.page++;
				this.gaEvent('MainPageNew', 'Rotator_Swipe_Next', 'RotatorRecommendations');
			}
			if (x - this.touch.x > 50) {
				this.page--;
				this.gaEvent('MainPageNew', 'Rotator_Swipe_Prev', 'RotatorRecommendations');
			}
		},
		imgLoaded(id) {
			Vue.set(this.loaded, id, true);
		},
		imgFailed(id, type) {
			if (this.failedWebp[id]) {
				this.killRotatorItem({id, type});
			} else {
				Vue.set(this.failedWebp, id, true);
			}
		},
		killRotatorItem({id, type}) {
			const index = this.listRaw.findIndex(({node: {payload: {__typename: itemType, id: itemId} = {}} = {}} = {}) => itemId === id && itemType === type);
			if (index > -1) {
				this.listRaw.splice(index, 1);
			}
		},
		watchUserDataOnce() {
			const _this = this;
			if (this.userData && this.userData.webClientId) {
				_this.initRecommendations();
			} else {
				const unwatch = this.$watch('userData', () => {
					_this.initRecommendations();
					unwatch();
				});
			}
		},
		sendSlonData(eventId, item, index = 'first') {
			if (item?.id) {
				this._slonik({
					project_id: 1,
					event_id: eventId,
					proposal_id: item.id,
					recommendation_version: this.version,
					screentype: this.isMobile ? 2 : 1,
					item_type: item.type === 'UsedAuto' ? 1 : 2,
					photo_id: item.photoId,
					action_block: index === 'first' ? 1 : index + 2,
					page: this.page + 1,
					photo_suffix: item.extraSuffix,
					a_b_test_version: item.extraSuffix.length > 0 ? 1 : 0,
				});
				return true;
			} else {
				return false;
			}
		},
		checkVisible(rotatorItem) {
			let positionTop = Array.isArray(this.$refs[rotatorItem]) ? this.$refs[rotatorItem][0].getBoundingClientRect().top : this.$refs[rotatorItem].getBoundingClientRect().top;
			let positionBottom = Array.isArray(this.$refs[rotatorItem]) ? this.$refs[rotatorItem][0].getBoundingClientRect().bottom : this.$refs[rotatorItem].getBoundingClientRect().bottom;

			// enable resubmission event for rotatorItem if user go to another page rotator
			if (this.changePageRotator) this.mapSendEvent565[rotatorItem] = false;

			return positionTop > 0 && positionBottom > 0 && positionTop < this.heightPage && positionBottom < this.heightPage;
		},
		checkRotatorPosition() {
			for (let rotatorAdv in this.$refs) {
				let sendStatus = false;
				if ((Array.isArray(this.$refs[rotatorAdv]) && this.$refs[rotatorAdv].length > 0) || rotatorAdv === 'rotatorMainPhoto') {
					// send event only if rotatorItem completely on the screen
					if (this.checkVisible(rotatorAdv) && !this.mapSendEvent565[rotatorAdv]) {
						if (rotatorAdv === 'rotatorMainPhoto') {
							sendStatus = this.sendSlonData(565, this.item);
						} else {
							let rotatorAdvIndex = rotatorAdv.slice(11);
							sendStatus = this.sendSlonData(565, this.items[rotatorAdvIndex], Number(rotatorAdvIndex));
						}
						// disable re-sending
						if (sendStatus) this.mapSendEvent565[rotatorAdv] = true;
					}
				}
			}
			// disable re-sending
			this.changePageRotator = false;
		}
	},
	watch: {
		items: function (val, oldVal) {
			if (val.length > 0 && val?.[0]?.id) {
				this.changePageRotator = val?.[0]?.id !== oldVal?.[0]?.id; // need for correct logger when user click button change page rotator
				this.checkRotatorPosition();
			}
		},
	},
	mounted() {
		if (_TARGET_ === 'client' && new Date() > new Date('08/01/2020 05:00')) {
			this.showInfoBlock = true;
		}
		this.mounted = true;
		this.watchUserDataOnce();

		for (let rotatorAdv in this.$refs) {
			let mainItem = rotatorAdv === 'rotatorMainPhoto';

			if ((Array.isArray(this.$refs[rotatorAdv]) && this.$refs[rotatorAdv].length > 0) || mainItem) {
				let keyTreshold = mainItem ? rotatorAdv : 'default';
				let onTreshold = intersectionTreshold[keyTreshold].on;
				let outTreshold = intersectionTreshold[keyTreshold].out;
				let sendStatus = false;
				let observer = new IntersectionObserver(([{intersectionRatio = false, isIntersecting = false} = entry] = [{}]) => {
					if (intersectionRatio >= onTreshold && isIntersecting && !this.mapSendEvent565[rotatorAdv]) { // item completely on the screen
						if (mainItem) {
							sendStatus = this.sendSlonData(565, this.item);
						} else {
							let rotatorAdvIndex = rotatorAdv.slice(11);
							sendStatus = this.sendSlonData(565, this.items[rotatorAdvIndex], Number(rotatorAdvIndex));
						}
						if (sendStatus) this.mapSendEvent565[rotatorAdv] = true; // disable re-sending
					} else if (intersectionRatio <= outTreshold && !isIntersecting && this.mapSendEvent565[rotatorAdv]) { // item completely out the screen
						this.mapSendEvent565[rotatorAdv] = false; // enable re-sending
					}
				}, {
					threshold: [outTreshold, onTreshold] // completely [out, on] the screen
				});
				observer.observe(Array.isArray(this.$refs[rotatorAdv]) ? this.$refs[rotatorAdv][0] : this.$refs[rotatorAdv]); // observe rotator elements
			}
		}
	}
};
