import {mapActions, mapGetters} from 'vuex';

const item = {
	id: 6,
	main: {params: {catalog_ranges: 1}},
	side: {params: {catalog_ranges: 30}},
};

const CATALOG_TOGGLE_MAIN = 'mainpageCatalogsNewsReview_Main';
const CATALOG_TOGGLE_SIDE = 'mainpageCatalogsNewsReview_Side';

export default {
	props: ['idSubActive'],
	init({store}) {
		return Promise.all([
			store.dispatch('news/fetchReviews', Object.assign({_target: CATALOG_TOGGLE_MAIN}, item.main.params)),
			store.dispatch('news/fetchReviews', Object.assign({_target: CATALOG_TOGGLE_SIDE}, item.side.params)),
		].map(item => item.catch(()=>console.error('init. Ошибка при запросе данных для каталогов. Обзоры'))))
	},
	computed: {
		...mapGetters({
			langId: 'lang/id',
			catalogs: 'news/toggleReviews',
		}),
		mainList() {
			let {
				markaCatalog: catalog = []
			} = this.catalogs(CATALOG_TOGGLE_MAIN) || {};
			return catalog.slice(0, 27)
		},
		sideList() {
			let {
				categoriesCatalog: catalog = []
			} = this.catalogs(CATALOG_TOGGLE_SIDE) || {};
			return catalog
		},
	},
	watch: {
		langId() {
			this.init();
		},
	},
	methods: {
		...mapActions({
			fetch: 'news/fetchReviews',
		}),
		getData(data = {}, _target = '') {
			this.fetch(Object.assign({_target}, data.params))
		},
		init() {
			this.getData(item.main, CATALOG_TOGGLE_MAIN);
			this.getData(item.side, CATALOG_TOGGLE_SIDE);
		},
	},
	mounted() {
		this.init()
	},
}
;
