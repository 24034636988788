export default {
	messages: {
		ru: {
			'html.buAuto.rasshirennuipoisk': 'Расширенный поиск',
			'html.buAuto.poisk': 'Поиск',
		},
		uk: {
			'html.buAuto.rasshirennuipoisk': 'Розширений пошук',
			'html.buAuto.poisk': 'Пошук',
		},
	}
};
