import {mapActions} from "vuex";

export default {
	name: 'BuNaToggle',
	i18n: require('./i18n').default,
	props: {
		value: {
			type: String,
			default: 'all',
		},
		tabRedForm: {
			type: Object,
		}
	},
	computed: {
		_value: {
			get() {
				return this.value;
			},
			set(value) {
				this.setIndices(value);
				this.$emit('input', value);
			}
		}
	},
	methods: {
		...mapActions({
			setIndices: 'search/state/setIndices',
		}),
	},
	mounted() {
		this.setIndices(this.value);
	}
};
