export default {
	messages: {
		ru: {
			'html.buAuto.novui': 'новый',
			'common.thousandKm': 'тыс. км',
			'Перевірені авто важливіші, ніж з рекламою': 'Проверенные авто важнее, чем с рекламой',
			'продавців на AUTO.RIA вже перевірили свої авто. Відтепер найвище у пошуку тільки перевірені! Ви маєте право знати, що купуєте.': 'продавцов на AUTO.RIA уже проверили свои авто. Теперь выше в поиске только проверенные! Вы имеете право знать, что покупаете.',
			'Детальніше': 'Подробнее',
		},
		uk: {
			'html.buAuto.novui': 'новий',
			'common.thousandKm': 'тис. км',
			'Перевірені авто важливіші, ніж з рекламою': 'Перевірені авто важливіші, ніж з рекламою',
			'продавців на AUTO.RIA вже перевірили свої авто. Відтепер найвище у пошуку тільки перевірені! Ви маєте право знати, що купуєте.': 'продавців на AUTO.RIA вже перевірили свої авто. Відтепер найвище у пошуку тільки перевірені! Ви маєте право знати, що купуєте.',
			'Детальніше': 'Детальніше',
		},
	}
};
