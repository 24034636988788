export default {
	messages: {
		ru: {
			'html.buAuto.region': 'Регион',
		},
		uk: {
			'html.buAuto.region': 'Регіон',
		},
	}
};
