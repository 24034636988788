import {mapGetters, mapActions, mapMutations} from 'vuex';

const currency = 1;

export default {
	i18n: require('./i18n').default,
	data: function () {
		return {}
	},
	computed: {
		...mapGetters({
			getPrice: 'search/state/newAuto/getPrice'
		}),
		from: {
			get: function () {
				const {from: value} = this.getPrice || {};
				return value
			},
			set: function (value) {
				value = parseInt(value);
				const from = Number.isFinite(value) ? value : undefined;
				const {to} = this.getPrice || {};
				this.setPrice({from, to, currency})
			}
		},
		to: {
			get: function () {
				const {to: value} = this.getPrice || {};
				return value
			},
			set: function (value) {
				value = parseInt(value);
				const to = Number.isFinite(value) ? value : undefined;
				const {from} = this.getPrice || {};
				this.setPrice({from, to, currency})
			}
		}
	},
	methods: {
		...mapMutations({
			setPrice: 'search/state/newAuto/setPrice'
		}),
	}
};
