module.exports = {
    data() {
        return {
            canSendEvent: true
        };
    },
    mounted() {
        let observer = new IntersectionObserver(([{intersectionRatio = false, isIntersecting = false} = entry] = [{}]) => {
            if (intersectionRatio === 1 && isIntersecting && this.canSendEvent) { // item completely on the screen
                let sendResult = this.sendSlonData(234);
                if (sendResult) this.canSendEvent = false; // disable re-sending
            } else if (intersectionRatio === 0 && !isIntersecting && !this.canSendEvent) { // item completely out the screen
                this.canSendEvent = true; // enable re-sending
            }
        }, {
            threshold: [0, 1] // completely [out, on] the screen
        });
        observer.observe(this.$el); // observe root element of the component
    },
    methods: {
        sendSlonData(eventId) {
            this._slonik({
                event_id: eventId,
                screentype: this.isDesktop ? 1 : 2,
                item_type: 99,
            });
        }
    }
};
