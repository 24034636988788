import {mapGetters, mapActions} from 'vuex';
import {cloneDeep} from '../../../../../../helpers/_lodash';

import Autocomplete from '../../UsedAuto/Autocomplete/index.vue';
import getFromSearchHistory from '../../../../../helpers/getFromSearchHistory';
import {suggestModel} from "../../../../../store/queries/suggest";

export default {
	i18n: require('./i18n').default,
	props: {
		search_type: String,
	},
	data: function () {
		return {
			mounted: false,
			suggestsRaw: [],
			currentSuggest: '',
		};
	},
	components: {
		'vue-autocomplete': Autocomplete
	},
	computed: {
		...mapGetters({
			brandsAll: 'shared/brands/newAuto/getAll',
			modelsAll: 'shared/models/newAuto/getAll',
			modelsForBrand: 'shared/models/newAuto/getByBrand',
			category: 'search/state/newAuto/getCategory',
			getSpecificBrand: 'search/state/newAuto/getSpecificBrand',
			getSpecificModel: 'search/state/newAuto/getSpecificModel'
		}),
		firstBrand() {
			return this.getSpecificBrand(0);
		},
		optionsRaw() {
			return Object.values(this.modelsForBrand(this.firstBrand, this.category) || {});
		},
		options() {
			const modelsList = Object.values(this.modelsForBrand(this.firstBrand)).sort((a, b) => a.name.localeCompare(b.name, 'en'));
			return modelsList
				.map(({count, marka_id: brand, model_id: value, name} = {}) => ({brand, count, value, name}));
		},
		selected: {
			get: function () {
				return this.getSpecificModel(0) || 0;
			},
			set: function (value) {
				this.setOnly(value);
			}
		},
		selectedName() {
			let {name} = this.modelsAll[this.selected]
			|| this.suggests.find(({value}) => Number(this.selected) === Number(value))
			|| {};
			return name;
		},
		suggests() {
			if (this.mounted) {
				try {
					return [...new Set(getFromSearchHistory('model'))]
						.reduce((result, item) => {
							const index = result.indexOf(result.find(({model_id}) => Number(model_id) === Number(item)));
							if (index !== -1) {
								let options = result
									.splice(index, 1)
									.map((item = {}) => {
										item.searched = true;
										return item;
									});
								result = options.concat(...result);
							}
							return result;
						}, cloneDeep(this.suggestsRaw));
				} catch (error) {
					// console.error(error);
				}
			}
			return this.suggestsRaw;
		},
		optionsSearched() {
			if (this.mounted) {
				const idsSearched = [...new Set(getFromSearchHistory('model'))];
				const options = this.optionsRaw;
				const searched = idsSearched.reduce((result, id) => {
					const optionSearched = options.find(({model_id: itemId}) => itemId === id);
					if (optionSearched) {
						result.push(Object.assign({}, optionSearched));
					}
					return result;
				}, []);
				return searched
					.map(({count, marka_id: brand, model_id: value, name} = {}) => ({brand, count, value, name}));
			} else {
				return [];
			}
		}
	},
	watch: {
		firstBrand() {
			if (!Object.values(this.modelsForBrand(this.firstBrand)).length) {
				const brandId = this.firstBrand;
				const {[brandId]: {category: categoryId} = {}} = this.brandsAll;
				this.fetch({category: categoryId, brand: brandId});
			}
		}
	},
	methods: {
		...mapActions({
			fetch: 'shared/models/newAuto/fetchByBrand',
			setOnly: 'search/state/newAuto/setOnlyModel',
		}),
		onSet(value) {
			this.setOnly(value);
		},
		onSuggest(payload) {
			this.currentSuggest = payload;
			this.suggestsRaw.splice(0, Infinity);
			return suggestModel({text: payload, category: this.category, brand: this.firstBrand}, this.search_type)
				.then((response) => {
					if (this.currentSuggest === payload) {
						this.suggestsRaw.splice(0, Infinity, ...response);
					}
				})
		},
	},
	mounted() {
		this.mounted = true;
	}
};
