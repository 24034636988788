export default {
	messages: {
		ru: {
			'common.lastSearches.forYourConvenience': 'Для вашего удобства AUTO.RIA выводит ваши последние поиски',
			'html.mainPage.review.title': 'Для Вашего удобства AUTO.RIA отображает Ваши последние поиски. Какие еще данные Вы хотели бы видеть на первой странице AUTO.RIA?',
			'common.lastSearches': 'Последние поиски',
			'common.hide': 'Скрыть',
			'html.buAuto.pokazatesche': 'Показать еще',
			'html.buAuto.zaden': 'за день',
			'html.buAuto.zachas': 'за час',
			'common.thankyou': 'Благодарим',
			'news.reviewIsSended': 'Ваш отзыв отправлен',
			'html.buAuto.zakrut': 'Закрыть',
			'feedback.comment': 'комментарий',
			'html.buAuto.ostavitotzuv': 'Оставить отзыв',
			'html.buAuto.vin': 'перевірено по VIN-коду',
		},
		uk: {
			'common.lastSearches.forYourConvenience': 'Для вашої зручності AUTO.RIA виводить ваші останні пошуки',
			'html.mainPage.review.title': 'Для Вашої зручності AUTO.RIA відображає Ваші останні пошуки. Які ще дані Ви хотіли б бачити на першій сторінці AUTO.RIA?',
			'common.lastSearches': 'Останні пошуки',
			'common.hide': 'Сховати',
			'html.buAuto.pokazatesche': 'Показати ще',
			'html.buAuto.zaden': 'за день',
			'html.buAuto.zachas': 'за годину',
			'common.thankyou': 'Дякуємо',
			'news.reviewIsSended': 'Ваш відгук відправлений',
			'html.buAuto.zakrut': 'Закрити',
			'feedback.comment': 'коментар',
			'html.buAuto.ostavitotzuv': 'Залишити відгук',
			'html.buAuto.vin': 'перевірено по VIN-коду',
		},
	}
};
