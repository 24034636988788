import {mapActions} from 'vuex';

export default {
	i18n: require('./i18n').default,
	mixins: [require('../../../../mixins/recommendationFeedLogger')],
	props: {
		data: Object,
		index: Number,
		version: String,
		userData: Object
	},
	data() {
		return {
			isLoadedData: false,
			items: [],
			imagesData: {},
		};
	},
	computed: {
		single() {
			return this.items?.length === 1 ? this.items[0] : null;
		}
	},
	mounted() {
		this.init();
	},
	methods: {
		...mapActions({
			getAdvertisementsForRotator: 'feed/getAdvertisementsForRotator'
		}),
		isItemOk: (item) => item.id && item.brand?.eng && item.model?.eng && item.photos?.main?.id && item.brand?.name && item.model?.name,
		urlTarget: (item) => item.id ? `/check_selection/${item.id}` : '#',
		itemImageUrlSrcset(item) {
			return this.isItemOk(item) ? `https://cdn.riastatic.com/photosnew/auto/photo/${item.brand.eng}-${item.model.eng}__${item.photos.main.id}f.webp` : '';
		},
		itemImageUrlSrc(item) {
			return this.isItemOk(item) ? `https://cdn.riastatic.com/photosnew/auto/photo/${item.brand.eng}-${item.model.eng}__${item.photos.main.id}.jpg` : '';
		},
		itemTitle(item) {
			return this.isItemOk(item) ? `${item.brand.name} ${item.model.name} ${item.year}` : '';
		},
		itemPrice: (item) => item.price?.main?.value || 0,
		init() {
			this.getAdvertisementsForRotator({ ...this.data.payload })
				.then((graphQlData) => {
					if (graphQlData && graphQlData.length > 0) {
						this.items = graphQlData;
						this.isLoadedData = true;
						this.imagesData = graphQlData.reduce((imagesData, item) => {
							if (!item.photos?.main?.id) {
								throw new Error('VinRotator: no photo data @vue#17B0B84CE46');
							}
							imagesData[String(item.photos.main.id)] = { loadedImg: false };
							return imagesData;
						}, {});
					} else {
						throw new Error('VinRotator: not full data @vue#17B0C393070');
					}
				})
				.catch(error => {
					this.$emit('ItemError', { type: 'CarCheckSelection', feedItem: this });
				});
		},
		sendSlonData(eventId) {
			if (!this.items.length) {
				return;
			}
			this._slonik({
				event_id: eventId,
				item_type: 22,
				marka_id: this.items.map(item => Number(item.brand?.id)),
				model_id: this.items.map(item => Number(item.model?.id)),
				race: this.items.map(item => item.race),
				year: this.items.map(item => item.year),
				price: this.items.map(item => item.price?.main?.value),
				screentype: this.isDesktop ? 1 : 2,
				owner_id: this.userData?.userId,
				user_id: this.userData?.userId,
				web_id: this.userData?.webClientId,
				proposal_id: this.items.map(item => Number(item.id)),
				position: this.index
			});
		},
		onClickVerify(item) {
			if (!this.isLoadedData) {
				return;
			}
			this._slonik({
				event_id: 235,
				marka_id: Number(item.brand?.id),
				model_id: Number(item.model?.id),
				race: item.race,
				year: item.year,
				price: item.price?.main?.value,
				item_type: 22,
				screentype: this.isDesktop ? 1 : 2,
				owner_id: this.userData.userId,
				user_id: this.userData.userId,
				web_id: this.userData.webClientId,
				proposal_id: Number(item.id),
				position: this.index
			});
		},
		isLoadedImg(auto) {
			return this.imagesData[String(auto.photos.main.id)].loadedImg;
		},
		onItemImgLoaded(auto) {
			this.imagesData[String(auto.photos.main.id)].loadedImg = true;
		},
		onItemImgFailed(item) {
			this.$emit('ItemError', { type: 'CarCheckSelection', feedItem: this });
		}
	}
};
