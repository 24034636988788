import {mapActions, mapGetters} from 'vuex';
import preparer from '../../../../helpers/preparer';

const itemPattern = {
	'_source.photo_additional.photo400_337': {handler: (path = '') => path ? `https://cdn.riastatic.com/docs/${path}` : ''},
	'_source.photo_additional.photo400_337_webp': {handler: (path, result) => result._source.photo_additional.photo400_337 ? result._source.photo_additional.photo400_337.replace(/jpg$/, 'webp') : ''},
	'_source.photo_additional.photo198_220': {handler: (path = '') => path ? `https://cdn.riastatic.com/docs/${path}` : ''},
	'_source.photo_additional.photo198_220_webp': {handler: (path, result) => result._source.photo_additional.photo198_220 ? result._source.photo_additional.photo198_220.replace(/jpg$/, 'webp') : ''},
	'fields.photo': {handler: (path = '') => path ? `https://cdn.riastatic.com/photos/${path.replace(/(\.jpg)$/, 'm$1')}` : ''},
	'fields.description': {default: ''},
	'fields.descript_photo': {default: ''},
	'fields.title': {default: ''},
	'fields.link': {default: ''},
	'fields.eng': {default: ''},
	'fields.news_id': {default: ''},
	'fields.journal': {default: ''},
};

const NEWS_TARGET = 'TestDrive';

export default {
	i18n: require('./i18n').default,
	data() {
		return {
			wasInView: false,
			loaded: false
		}
	},
	computed: {
		...mapGetters({
			category: 'shared/categories/category',
			subCategories: 'shared/subCategories/getMain',
			toggle: 'news/toggle'
		}),
		news() {
			const {data: news} = this.toggle(NEWS_TARGET) || {};
			return Array.isArray(news)?
				news.map(item => preparer(item, itemPattern)):
				[]
		},
		item() { // first item
			let [item] = this.news || [];
			return item
		},
		others() { // others items
			return this.news.slice(1)
		},
	},
	methods: {
		...mapActions({
			fetchFor: 'news/fetch'
		}),
		init() {
			return this.fetchFor({_target: NEWS_TARGET, rubric_id: 141, size: 3})
		}
	},
	serverPrefetch(){
		return this.init();
	},
	mounted() {
		this.init();
		const _this = this;
		try {
			const IOW = require('../../../../helpers/IntersectionObserver_screen_333_01');

			IOW.add(this.$el, (entry = {}) => {
				if (entry.isIntersecting) {
					_this.wasInView = true;
					IOW.del(entry.target);
				}
			});
		} catch (error) {
			console.error(error);
			_this.wasInView = true;
		}
	}
};
