export default {
	mounted() {
		googletag = googletag || {};
		googletag.cmd = googletag.cmd || [];
		googletag.cmd.push(function () {
			let dfpSlots = [
				googletag.defineSlot('/21727809921/AUTO.RIA_desktop/used_auto_main', [300, 250], 'div-gpt-ad-300x250top-1'),
				googletag.defineSlot('/21727809921/AUTO.RIA_branding/used_auto_main', [728, 90], 'div-gpt-ad-728x90-1')
			];
			dfpSlots.forEach(slot => {
				if (slot) slot.addService(googletag.pubads());
			});
			googletag.display('div-gpt-ad-300x250top-1');
			googletag.display('div-gpt-ad-728x90-1');
		});
	}
};
