export default {
	messages: {
		ru: {
			'mainPage.clubAuto': 'Присоединяйтесь к сообществу автолюбителей AUTO.RIA',
			'Поиск авто': 'Поиск авто',
		},
		uk: {
			'mainPage.clubAuto': 'Приєднуйтесь до спільноти автолюбителів AUTO.RIA',
			'Поиск авто': 'Пошук авто',
		},
	}
};
