const projection = require('./projection').default;

export default {
	i18n: require('./i18n').default,
	mixins: [require('../../../../mixins/recommendationFeedLogger')],
	props: ['data', 'index', 'version', 'userData'],
	computed: {
		item() {
			return projection(this.data?.payload);
		},
		rating() {
			return String(Math.ceil(Number(this.item.rating)) || 0);
		},
	},
	methods: {
		sendSlonData(eventId) {
			this._slonik({
				event_id: eventId,
				item_type: 19,
				position: this.index,
				review_id: this.data?.payload?.id,
				screentype: this.isDesktop ? 1 : 2,
				recommendation_version: this.version
			});
			return true;
		},
		logClick() {
			this.gaEvent('MainPageNew', `Lenta_Position${this.index}_Review`, 'Lenta');
			this.sendSlonData(235);
		},
	},
};
