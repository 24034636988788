export default {
	messages: {
		ru: {
			'catalogs.categories.otherCategoriesIn': 'Типы транспорта',
			'html.buAuto.legkovue_used': 'Легковые б/у',
			'html.buAuto.vodnui': 'Водный',
			'html.buAuto.novueavto': 'Новые авто',
			'Все марки': 'Все марки',
			'Смотреть все марки': 'Смотреть все марки→'
		},
		uk: {
			'catalogs.categories.otherCategoriesIn': 'Типи транспорту',
			'html.buAuto.legkovue_used': 'Легкові б/у',
			'html.buAuto.vodnui': 'Водний',
			'html.buAuto.novueavto': 'Нові авто',
			'Все марки': 'Всі марки',
			'Смотреть все марки': 'Дивитись всі марки→'
		},
	}
};
