// eslint-disable-next-line import/no-extraneous-dependencies
import { mapActions, mapGetters } from 'vuex';

import { newAuto, bu as buCategories } from '../../../../constants/categories';
import { autoRia } from '../../../../constants/origins';

export default {
	// eslint-disable-next-line global-require
	i18n: require('./i18n').default,
	data() {
		return {
			active: 0,
		};
	},
	watch: {
		active() {
			const {
				[this.active]: {
					type, id, size,
				} = {},
			} = this.categoriesList;
			this.fetch({ categoryId: id, type, size });
		},
		langId() {
			const {
				[this.active]: {
					type, id, size,
				} = {},
			} = this.categoriesList;
			this.fetch({ categoryId: id, type, size });
			this.fetchCategories();
		},
	},
	computed: {
		...mapGetters({
			category: 'shared/categories/category',
			subCategories: 'shared/subCategories/getMain',
			subcategory: 'shared/subCategories/subcategory',
		}),
		categoriesListReducedWithSubs() {
			return this.categoriesList
				.filter(({ id } = {}) => id !== 1)
				.map((item = {}) => {
					const { id, type, size, url } = item;
					const category = { ...item };
					const sub = this.subcategory({ categoryId: id, type, size });
					category.$sub = Array.isArray(sub) ? sub : [];
					category.$sub = category.$sub.map((subCat) => ({
						...subCat,
						href: url
							? `${url}${subCat.link}`
							: `${autoRia.origin}${this.langPrefix}${subCat.link}`,
					}))
					return category;
				});
		},
		categoriesList() {
			let categories = Object.values(buCategories);
			categories.splice(1, 0, ...(Object.values(newAuto)));
			categories = categories.sort((a, b) => a.orderBy - b.orderBy);

			return categories
				.map((item = {}) => {
					const category = { ...item };
					const { id, langPrefixNewAuto } = category;
					const currentLangPrefix = langPrefixNewAuto
						? this.langPrefixNewAuto
						: this.langPrefix;
					if (!category.name) {
						const { name } = this.category(id) || {};
						category.name = name;
					}
					category.href = '';
					if (category.url) {
						category.href = `${category.url}${currentLangPrefix}/`;
					} else if (category.link) {
						category.href = `${currentLangPrefix}${category.link}`;
					}
					return category;
				});
		},
	},
	methods: {
		...mapActions({
			fetch: 'shared/subCategories/fetch',
			fetchCategories: 'shared/categories/fetch',
		}),
		init() {
			const ctx = this;

			return Promise.all(
				Object.values(buCategories)
					.filter(({ id } = {}) => id !== 1)
					.map(({ id, type, size } = {}) => ctx.fetch({ categoryId: id, type, size })),
			)
				.catch(() => console.error('Ошибка при получении данных категорий'));
		},
		changeCategory(id, gaParams) {
			this.active = id;
			this.gaEvent(gaParams.page, gaParams.element, gaParams.block);
		},
		goToCatalog(payload) {
			// eslint-disable-next-line no-restricted-globals,no-undef
			location.href = payload;
		},
	},
	serverPrefetch() {
		return this.init();
	},
	mounted() {
		return this.init();
	},
};
