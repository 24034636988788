import { mapActions, mapGetters } from 'vuex';
import _laxios from '../../../store/_laxios';

const { get } = require('../../../helpers/cookie');

const projection = require('./projection').default;

const feed = import('./feed.graphql');

function fetchFeed(ids, { langId = 2, limit = 48, version = '' } = {}) {
  ids = Array.isArray(ids) ? ids.join(',') : '';
  return feed
    .then(({ default: query }) => query)
    .then((query) => {
      const variables = {
        ids, langId, limit, version,
      };
      return _laxios.graphql
        .request({ data: { query, variables } })
        .catch((error) => {
                	const message = `[GRAPH_QL API] feed: ${JSON.stringify(variables)}`;
          error.message = error?.message ? `${message}\n${error.message}` : message;
          throw error;
        });
    })
    .then(({ data: { feed } = {} } = {}) => feed);
}

const componentMap = {
  UsedAuto: 'vue-used',
  NewAuto: 'vue-na',
  News: 'vue-news',
  AutoCostEstimation: 'vue-auto-cost-estimation',
  AutoReview: 'vue-autoreview',
  CompareCar: 'vue-compare-car',
  AveragePrice: 'vue-average-price',
  CarCheckSelection: 'vue-vin-rotator',
  CarCheck: 'vue-banner',
  LoginBlock: 'vue-login-block',
};

module.exports = {
  i18n: require('./i18n').default,
  props: {
    watched: Array,
    viewHistory: Array,
  },
  data: () => ({
    feedSlices: 3,
    adViewed: false,
    listRaw: [],
    version: '3.0.12',
  }),
  components: {
    'vue-item-wrapper': require('./ItemWrapper/index.vue').default,
    'vue-used': require('./Used/index.vue').default,
    'vue-na': require('./Na/index.vue').default,
    'vue-news': require('./News/index.vue').default,
    'vue-auto-cost-estimation': () => import('./AutoCostEstimation/index.vue'),
    'vue-autoreview': () => import('./AutoReview/index.vue'),
    'vue-compare-car': require('./CompareCar/index.vue').default,
    'vue-average-price': require('./AveragePrice/index.vue').default,
    'vue-vin-rotator': require('./VinRotator/index.vue').default,
    'vue-banner': require('./VinBanner/index.vue').default,
    'vue-login-block': () => import('./LoginBlock/index.vue'),
  },
  computed: {
    ...mapGetters({
      userData: 'Common/userData',
    }),
    list() {
      const list = (
        Array.isArray(this.listRaw)
          ? this.listRaw
          : []
      )
        .map((item = {}) => ({ component: componentMap[item?.type], ...item }))
        .filter(({ component } = {}) => Boolean(component));
      list.splice(1, 0, { component: 'slot' });
      if (this.mounted && !(this.userData && this.userData.userId)) {
        list.splice(4, 0, { component: 'vue-login-block', type: 'LoginBlock', blockType: (this.getTestCookie % 3) + 1 });
      }
      return list;
    },
    listSlice() {
      return this.list.slice(0, this.feedSlices * 6 + 1);
    },
    isShowMoreVisible() {
      return this.listSlice.length < this.list.length;
    },
    showBlockItem() {
      return _TARGET_ === 'client' && Number(get('test_block_feed')) === 42;
    },
    getTestCookie() {
      return _TARGET_ === 'client' && Number(get('test_new_features'));
    },
  },
  methods: {
    ...mapActions({
      fetchFeedRecomendation: 'feed/fetchFeedRecomendation',
    }),
    initRecommendations() {
      return this.fetchFeedRecomendation({
        history: this.viewHistory,
        langId: this.langId,
        version: this.version,
      })
        .then((response = {}) => {
          let { feed, version } = response;
          this.version = version;
          feed = Array.isArray(feed) ? feed.map(projection) : [];
          this.listRaw.splice(0, this.listRaw.length, ...feed);
        })
        .catch(console.error);
    },
    showMore() {
      this.gaEvent('MainPageNew', `'Lenta_More_${this.feedSlices}`, 'Lenta');
      this.feedSlices += 2;
      this._slonik({
        project_id: 1,
        event_id: 762,
        owner_id: (this.userData && this.userData.userId) ? Number(this.userData.userId) : 0,
        screentype: this.isDesktop ? 1 : (this.isMobile ? 2 : 3),
      });
      setTimeout((y) => window.scrollTo(window.scrollX, y), 9, window.scrollY);
    },
    intersectingBanner({ isIntersecting } = {}) {
      if (!this.adViewed && isIntersecting) {
        this.adViewed = true;
        this._slonik({
          project_id: 1,
          event_id: 234,
          version: this.version,
          screentype: this.isDesktop ? 1 : 2,
          item_type: 0,
        });
      }
    },
    watchUserDataOnce() {
      const _this = this;
      if (this.userData && this.userData.webClientId) {
        _this.initRecommendations();
      } else {
        const unwatch = this.$watch('userData', () => {
          _this.initRecommendations();
          unwatch();
        });
      }
    },
    onImageError(error = {}) {
      const { id, type } = error;
      const index = this.listRaw.findIndex(({ type: itemType, payload: { id: itemId } = {} } = {}) => itemId === id && itemType === type);
      if (index > -1) {
        this.listRaw.splice(index, 1);
      }
      console.error(error, { id, type });
    },
    onItemError({ type, feedItem: { data: { payload = {} } = {} } } = {}) {
      const index = this
        .listRaw
        .findIndex(({ type: itemType, payload: itemPayload = {} } = {}) => itemType === type && JSON.stringify(payload) === JSON.stringify(itemPayload));
      if (index > -1) {
        this.listRaw.splice(index, 1);
      }
    },
  },
  mounted() {
    this.mounted = true;
    this.watchUserDataOnce();
  },
};
