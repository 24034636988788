export default {
	messages: {
		ru: {
			'common.lookMore': 'Показать еще',
			'html.buAuto.recomenduet': 'рекомендует',
		},
		uk: {
			'common.lookMore': 'Показати ще',
			'html.buAuto.recomenduet': 'рекомендує',
		},
	}
};
