const MODES = {};
MODES[MODES['OPENED'] = 1] = 'OPENED';
MODES[MODES['CAUSE'] = 2] = 'CAUSE';
MODES[MODES['HIDDEN'] = 3] = 'HIDDEN';
MODES[MODES['HIDDEN_COMPLETELY'] = 4] = 'HIDDEN_COMPLETELY';

module.exports.MODES = MODES;

const FEED_ITEM_TYPES = {};
FEED_ITEM_TYPES[FEED_ITEM_TYPES['USED_AUTO'] = 1] = 'USED_AUTO';
FEED_ITEM_TYPES[FEED_ITEM_TYPES['NEW_AUTO'] = 2] = 'NEW_AUTO';
FEED_ITEM_TYPES[FEED_ITEM_TYPES['NEWS'] = 3] = 'NEWS';
FEED_ITEM_TYPES[FEED_ITEM_TYPES['COMPARE_CAR'] = 20] = 'COMPARE_CAR';
FEED_ITEM_TYPES[FEED_ITEM_TYPES['AVERAGE_PRICE'] = 21] = 'AVERAGE_PRICE';
FEED_ITEM_TYPES[FEED_ITEM_TYPES['VIN_ROTATOR'] = 22] = 'VIN_ROTATOR';
FEED_ITEM_TYPES[FEED_ITEM_TYPES['VIN_BANNER'] = 23] = 'VIN_BANNER';

module.exports.FEED_ITEM_TYPES = FEED_ITEM_TYPES;
