import slonDataLogger from '../../../mixins/slonDataLogger';

export default {
  name: 'Banner',
  mixins: [slonDataLogger],
  props: {
    bannerData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    bannerName() {
      return this.bannerData?.name || '';
    },
    banner() {
      const {
        data: {
          desktop = {},
          mobile = {},
        } = {},
      } = this.bannerData;

      return this.isDesktop ? desktop : mobile;
    },
    picture() {
      const defaultPicture = {
        avif: '',
        webp: '',
        img: {
          src: '',
          width: 0,
          height: 0,
        },
      };

      return this.banner.picture || defaultPicture;
    },
  },
  methods: {
    loggerBanner() {
      this._slonik({
        event_id: 1467,
        current_point_action: this.bannerName,
        current_url: this.banner.link,
        previous_url: this.clearAutoRiaUrl(window?.location?.pathname),
      });
    },
  },
};
