export default {
	messages: {
		ru: {
			'Середня ціна': 'Средняя цена',
			'Детальніше про середню ціну': 'Подробнее о средней цене',
			'Дивитись': 'Смотреть',
			'пропозицій': 'предложений',
			'до': 'до',
			'року': 'года',
		},
		uk: {
			'Середня ціна': 'Середня ціна',
			'Детальніше про середню ціну': 'Детальніше про середню ціну',
			'Дивитись': 'Дивитись',
			'пропозицій': 'пропозицій',
			'до': 'до',
			'року': 'року',
		}
	}
};
