import getDeviceOs from '../../../helpers/getDeviceOs';
import {COOKIE_NAME} from './isActive';
import {set} from '../../../helpers/cookie';

const COOKIE_INTERVAL = 86400; // 60 * 60 * 24 - 1 day

export default {
	name: 'PromoApp',
	data() {
		return {
			iosLink: 'https://redirect2download.ria.com/redirect/2?target=affiliate_ukrnet',
			androidLink: 'https://redirect2download.ria.com/redirect/2?target=affiliate_ukrnet',
			hidePopap: 'hide',
			os: '',
		};
	},
	methods: {
		closePopap(){
			this.gaEvent('MainPageNew', 'promo_popap_app_ukrnet_close', 'promo_popap_app_ukrnet_close');
			this.hidePopap = 'hide';
			set(COOKIE_NAME, JSON.stringify(true), {expires: COOKIE_INTERVAL * 90});
		},
		downloadApp(){
			this.gaEvent('MainPageNew', 'promo_popap_app_ukrnet_down', 'promo_popap_app_ukrnet_down');
			this.hidePopap = 'hide';
			set(COOKIE_NAME, JSON.stringify(true), {expires: COOKIE_INTERVAL * 365});
		},
		show() {
			this.os = getDeviceOs();
			this.hidePopap = '';
		},
	},
	mounted() {
		this.show();
		this.gaEvent('MainPageNew', 'promo_popap_app_ukrnet_show', 'promo_popap_app_ukrnet_show');
	},
	i18n: {
		messages: {
			uk: {
				'Перевірені авто': 'Перевірені авто',
				'у твоєму смартфоні': 'у твоєму смартфоні',
				'Завантажте': 'Загрузите',
			},
			ru: {
				'Перевірені авто': 'Проверенные авто',
				'у твоєму смартфоні': 'в твоем смартфоне',
				'Завантажте': 'Завантажте',
			}
		}
	}
};
