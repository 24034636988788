const projection = require('../../../../helpers/projection').default;

const schema = {
    type: (s) => s?.type,
    photo: (s) => s?.photo,
    payload: (s) => s?.payload,
    key: (s) => s?.key
}

exports.default = projection(schema);
