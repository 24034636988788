import { agroRia } from './origins';

module.exports.bu = {
	1: {
		id: 1, orderBy: 1, icon: 'cars', link: '/legkovie/', type: 'bu', name: 'Легковые б/у', l10n: 'html.buAuto.legkovue_used',
	}, // Я не специально, меня заставили T_T
	2: {
		id: 2, orderBy: 3, icon: 'moto', link: '/moto/', type: 'bu', size: 60,
	},
	6: {
		id: 6, orderBy: 4, icon: 'trucks', link: '/truck/', type: 'bu',
	},
	5: {
		id: 5, orderBy: 5, icon: 'trailers', link: '/trailer/', type: 'bu',
	},
	4: {
		id: 4, orderBy: 6, icon: 'spectex', link: '/spectehnika/', type: 'bu',
	},
	10: {
		id: 10, orderBy: 7, icon: 'selkhoztekhnika', link: '/selkhoztekhnika/', type: 'bu', url: agroRia.origin, langPrefixNewAuto: agroRia.langPrefixNewAuto,
	},
	7: {
		id: 7, orderBy: 8, icon: 'autobus', link: '/bus/', type: 'bu',
	},
	3: {
		id: 3, orderBy: 9, icon: 'water', link: '/vodnyj-transport/', type: 'bu', name: 'Водный', l10n: 'html.buAuto.vodnui',
	},
	9: {
		id: 9, orderBy: 10, icon: '', link: '/air-transport/', type: 'bu',
	},
	8: {
		id: 8, orderBy: 11, icon: '', link: '/autohouse/', type: 'bu',
	},
};

module.exports.newAuto = {
	1: {
		id: 1, orderBy: 2, icon: 'cars', link: '/newauto/category-legkovie/', type: 'new', name: 'Новые авто', l10n: 'html.buAuto.novueavto',
	}, // Ага, еще один пример КоОП
};
