export default {
	messages: {
		ru: {
			'catalogs.reviews.monthsNames.october': 'октября',
			'catalogs.reviews.monthsNames.november': 'ноября',
			'catalogs.reviews.monthsNames.december': 'декабря',
			'catalogs.reviews.monthsNames.september': 'сентября',
			'catalogs.reviews.monthsNames.august': 'августа',
			'catalogs.reviews.monthsNames.june': 'июня',
			'catalogs.reviews.monthsNames.july': 'июля',
			'catalogs.reviews.monthsNames.may': 'мая',
			'catalogs.reviews.monthsNames.april': 'апреля',
			'catalogs.reviews.monthsNames.february': 'февраля',
			'catalogs.reviews.monthsNames.march': 'марта',
			'catalogs.reviews.monthsNames.january': 'января',
			'common.today': 'Сегодня',
			'common.yesterday': 'Вчера',
			'common.readMore': 'Читать больше',
			'common.news': 'новостей',
		},
		uk: {
			'catalogs.reviews.monthsNames.october': 'жовтня',
			'catalogs.reviews.monthsNames.november': 'листопада',
			'catalogs.reviews.monthsNames.december': 'грудня',
			'catalogs.reviews.monthsNames.september': 'вересня',
			'catalogs.reviews.monthsNames.august': 'серпня',
			'catalogs.reviews.monthsNames.june': 'червня',
			'catalogs.reviews.monthsNames.july': 'липня',
			'catalogs.reviews.monthsNames.may': 'травня',
			'catalogs.reviews.monthsNames.april': 'квітня',
			'catalogs.reviews.monthsNames.february': 'лютого',
			'catalogs.reviews.monthsNames.march': 'березня',
			'catalogs.reviews.monthsNames.january': 'січня',
			'common.today': 'Сьогодні',
			'common.yesterday': 'Вчора',
			'common.readMore': 'Читати більше',
			'common.news': 'новин',
		},
	}
};
